import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SERVER_ENDPOINT } from '../constants/api_endpoint';
import { toast } from 'react-toastify';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';


function SignUp() {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [values, setValues] = useState({
    name: '',
    email: '',
    mobile: '',
    affiliate_key: '',
    password: '',
  });
  const [errorMsg, setErrorMsg] = useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [otp, setOtp] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const toggleConfirmPasswordVisibility = () =>
    setConfirmPasswordVisible(!confirmPasswordVisible);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate if all fields are filled
    if (!values.name || !values.email || !values.mobile || !values.password) {
      setErrorMsg("Please fill all the fields.");
      return;
    }

    // Check if passwords match
    if (values.password !== values.confirmPassword) {
      setErrorMsg("Passwords do not match.");
      return;
    }

    setErrorMsg("");
    setLoading(true);

    try {
      // Send signup request to the server
      const response = await axios.post(
        `${SERVER_ENDPOINT}/api/user/signup`,
        values
      );
      console.log("Signup Response:", response.data);

      if (response.status === 201) {
        const { name, email, userId, token } = response.data;

        // Construct user data object including all necessary fields
        const userData = {
          name,
          email,
          mobile: values.mobile,
          userId,
        };

        // Save user data and token to localStorage
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("token", token);

        setUserId(userId);
        setShowEmailModal(true);
        toast.success(
          response.data.message ||
            "OTP sent to email, Please verify your email!"
        );
      } else {
        toast.error(response.data.message || "An unexpected error occurred.");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      toast.error(errorMessage);
      console.error("Signup Error:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleEmailVerification = async (e) => {
    e.preventDefault();
    if (!otp) {
      setErrorMsg('Please enter the OTP sent to your email.');
      return;
    }
    setLoading(true); 
    try {
      const response = await axios.post(`${SERVER_ENDPOINT}/api/user/verify-email`, { userId, otp });
      console.log('Email Verification Response:', response.data);
  
      if (response.status === 200) {
        setIsEmailVerified(true);
        setOtp('');
        setShowEmailModal(false); 
        setShowMobileModal(true); 
        toast.success(response.data.message || 'Otp sent to mobile, Please verify your mobile!');
      } else {
        toast.error(response.data.message || 'An unexpected error occurred.');
      }
    } catch (error) {
      setSubmitButtonDisabled(false);
    
      // Check if the error has a response object and a message
      const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
      
      toast.error(errorMessage);
      console.error('Signup Error:', error);
    }
    
    finally {
      setLoading(false)
  }
  };
  

  const handleMobileVerification = async (e) => {
    e.preventDefault();
    if (!otp) {
      setErrorMsg('Please enter the OTP sent to your mobile.');
      return;
    }
    setLoading(true); 
    try {
      const response = await axios.post(`${SERVER_ENDPOINT}/api/user/verify-mobile`, { userId, otp },  { withCredentials: true });
      console.log('Mobile Verification Response:', response.data);
      if (response.status === 200) {
        alert('Mobile number verified successfully. Registration complete.');
        toast.success(response.data.message || 'SignUp Successful');
        navigate('/dashboard');
      } else {
        toast.error(response.data.message || 'An unexpected error occurred.');
      }
    } catch (error) {
      setSubmitButtonDisabled(false);
    
      // Check if the error has a response object and a message
      const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
      
      toast.error(errorMessage);
      console.error('Signup Error:', error);
    }
    finally {
      setLoading(false)
  }
  };

  return (
    <>
      <div className="text-center items-center md:mt-24 mt-20 w-full mx-0">
  <form
    className="flex flex-col space-y-2 h-screen md:h-auto max-w-xl md:my-10 mx-auto px-4 pt-5 md:py-8 rounded-lg shadow-lg bg-white"
    onSubmit={handleSubmit}
  >
    <div className="text-center">
      <img
        src="govyapar logo.png"
        className="md:px-56 px-32"
        alt="Govyapar Logo"
      />
      <h1 className="text-xl font-bold">Welcome to GoVyapar's Affiliate Program</h1>
      <h1 className="text-md font-bold">Earn Flat 30% reward for each referral</h1>
    </div>

    {/* Name Field */}
    <label className="text-black text-left">Name</label>
    <div className="flex">
      <i className="fa fa-user icon text-black p-2 rounded-l-md bg-yellow-500 border-t-2 border-l-2 border-b-2 border-black"></i>
      <input
        type="text"
        name="name"
        placeholder="Enter your Name"
        className="w-full p-1 rounded-r-md border-2 border-black"
        required
        onChange={(event) =>
          setValues((prev) => ({ ...prev, name: event.target.value }))
        }
      />
    </div>

    {/* Email Field */}
    <label className="text-black text-left">Email</label>
    <div className="flex">
      <i className="fa fa-envelope icon text-black p-2 rounded-l-md bg-yellow-500 border-t-2 border-l-2 border-b-2 border-black"></i>
      <input
        type="email"
        name="email"
        placeholder="Enter your Email"
        className="w-full p-1 rounded-r-md border-2 border-black"
        required
        onChange={(event) =>
          setValues((prev) => ({ ...prev, email: event.target.value }))
        }
      />
    </div>

    {/* Mobile Field */}
    <label className="text-black text-left">Mobile</label>
    <div className="flex">
      <i className="fas fa-phone text-black p-2 rounded-l-md border-t-2 border-l-2 border-b-2 border-black bg-yellow-500"></i>
      <input
        type="text"
        name="mobile"
        placeholder="Enter your Mobile"
        className="w-full p-1 rounded-r-md border-2 border-black"
        required
        onChange={(event) =>
          setValues((prev) => ({ ...prev, mobile: event.target.value }))
        }
      />
    </div>

    {/* Affiliate Key Field */}
    <label className="text-black text-left">Affiliate Key</label>
    <div className="flex">
      <i className="fa-solid fa-id-card text-black p-2 rounded-l-md border-t-2 border-l-2 border-b-2 border-black bg-yellow-500"></i>
      <input
        type="text"
        name="affiliate_key"
        placeholder="Enter Affiliate Key"
        className="w-full p-1 rounded-r-md border-2 border-black"
        onChange={(event) =>
          setValues((prev) => ({ ...prev, affiliate_key: event.target.value }))
        }
      />
    </div>

    {/* Password Field */}
    <label className="text-black text-left">Password</label>
    <div className='flex relative'>
      <i className="fas fa-key text-black p-2 rounded-l-md border-t-2 border-l-2 border-b-2 border-black bg-yellow-500"></i>
      <input
        name='password'
        placeholder='Enter your Password'
        type={passwordVisible ? 'text' : 'password'}
        className='w-full p-1 rounded-r-md border-2 border-black'
        required
        value={values.password}
        onChange={(event) =>
          setValues((prev) => ({ ...prev, password: event.target.value }))
        }
      />
      <span
        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
        onClick={togglePasswordVisibility}
      >
        {passwordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined className="text-yellow-500" />}
      </span>
    </div>

    {/* Confirm Password Field */}
    <label className="text-black text-left">Confirm Password</label>
    <div className='flex relative'>
      <i className="fas fa-key text-black p-2 rounded-l-md border-t-2 border-l-2 border-b-2 border-black bg-yellow-500"></i>
      <input
        name='confirmPassword'
        placeholder='Confirm your Password'
        type={confirmPasswordVisible ? 'text' : 'password'}
        className='w-full p-1 rounded-r-md border-2 border-black'
        required
        value={values.confirmPassword}
        onChange={(event) =>
          setValues((prev) => ({ ...prev, confirmPassword: event.target.value }))
        }
      />
      <span
        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
        onClick={toggleConfirmPasswordVisibility}
      >
        {confirmPasswordVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined className="text-yellow-500" />}
      </span>
    </div>

    {/* Error Message */}
    <p className="text-md text-red-500">{errorMsg}</p>

    {/* Loading Indicator or Submit Button */}
    {loading ? (
      <div className="flex justify-center items-center py-4">
        <div className="dots-loader">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    ) : (
      <button
        type="submit"
        className="bg-yellow-500 text-white py-2 px-4 rounded-lg border border-yellow-600"
        disabled={submitButtonDisabled}
      >
        {submitButtonDisabled ? 'Check Your Email' : 'Submit'}
      </button>
    )}

    {/* Login Link */}
    <h1 className="text-md text-black">
      Already have an account?{' '}
      <Link to="/signIn">
        <span className="text-blue-500">Log In</span>
      </Link>
    </h1>
  </form>

  {/* Email Verification Modal */}
  {showEmailModal && (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80">
        <h2 className="text-md font-bold mb-4">Verify Your Email</h2>
        <form onSubmit={handleEmailVerification}>
          <label className="text-black text-left">Enter OTP</label>
          <div className="flex mb-4">
            <input
              type="text"
              name="otp"
              placeholder="Enter the OTP"
              className="w-full p-1 rounded-md border-2 border-black"
              required
              value={otp}
              onChange={(event) => setOtp(event.target.value)}
            />
          </div>
          {loading ? (
            <div className="flex justify-center items-center py-4">
              <div className="dots-loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          ) : (
            <button
              type="submit"
              className="bg-yellow-500 hover:bg-yellow-600 w-full text-black font-bold py-2 px-3 rounded-md shadow-sm"
            >
              Verify Email
            </button>
          )}
        </form>
        <button
          onClick={() => setShowEmailModal(false)}
          className="mt-4 text-blue-500 underline"
        >
          Close
        </button>
      </div>
    </div>
  )}

  {/* Mobile Verification Modal */}
  {isEmailVerified && showMobileModal && (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80">
        <h2 className="text-md font-bold mb-4">Verify Your Mobile</h2>
        <form onSubmit={handleMobileVerification}>
          <label className="text-black text-left">Enter OTP</label>
          <div className="flex mb-4">
            <input
              type="text"
              name="otp"
              placeholder="Enter the OTP"
              className="w-full p-1 rounded-md border-2 border-black"
              required
              value={otp}
              onChange={(event) => setOtp(event.target.value)}
            />
          </div>
          {loading ? (
            <div className="flex justify-center items-center py-4">
              <div className="dots-loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          ) : (
            <button
              type="submit"
              className="bg-yellow-500 hover:bg-yellow-600 w-full text-black font-bold py-2 px-3 rounded-md shadow-sm"
            >
              Verify Mobile
            </button>
          )}
        </form>
        <button
          onClick={() => setShowMobileModal(false)}
          className="mt-4 text-blue-500 underline"
        >
          Close
        </button>
      </div>
    </div>
  )}
</div>

    </>
  );
}

export default SignUp;
