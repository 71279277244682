import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    {/* Adjusted ToastContainer to display in the middle center */}
    <ToastContainer 
      position="top-center" // This will initially place it at the top-center
      autoClose={3000} 
      hideProgressBar={false} 
      newestOnTop={false} 
      closeOnClick 
      rtl={false} 
      pauseOnFocusLoss 
      draggable 
      pauseOnHover 
      style={{ 
        top: '20%', // Vertically center
        left: '50%', // Horizontally center
        transform: 'translate(-50%, -50%)', // Adjust for exact center positioning
        width: 'fit-content' // Ensure width is not stretched
      }} 
    />
    <App />
  </>
);
