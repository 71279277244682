import React, { useRef, useState } from 'react'
import {motion} from "framer-motion";
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Tabs, TabsHeader, TabsBody, Tab, TabPanel,} from "@material-tailwind/react";
import ModalCFO from './ModalCFO';
import Modal from './Modal';
import Modal2 from './Modal2';

function VirtualCfo () {
      // --------------------------------------------subscribe to our newsletter--------------------------------
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_2shjaqt', 
        'template_yb1ikfz', 
        form.current, {
        publicKey: 'KapdrjZ67SvjEm1Jl',
      })
      .then(
        () => {
          toast.success('Subscribed', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
            });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  // --------------------------------------------contact---------------------------
  const[user, setUser] = useState(
    {
        Name: '', Number:'', Message:''
    }
  )
  // const [errorMsg, setErrorMsg] = useState("");
  let name, value
  const data = (e) =>
  {
    console.log(user)
    name = e.target.name;
    value = e.target.value;
    setUser({...user, [name]:value});
  }
  const getdata = async (e) => 
  {
  if (!user.Name || !user.Number || !user.Message) {
    // setErrorMsg("Fill all fields");
    return;
  }
    // setErrorMsg("");
    
    const{Name, Number, Message} = user;
    e.preventDefault();
    const options = {
        method: 'POST',
        headers: {
            'Content-type' : 'application/json'
        },
        body: JSON.stringify({
            Name, Number, Message
        })
    }
    const res = await fetch(
        'https://govyapar-contact-default-rtdb.firebaseio.com/UserData.json',
        options
        )
        if(res)
        {
          toast.success('Message sent successfully', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
            });
        }
        else
        {
            alert("Error Ocurred")
        }
      }

      const [activeTab, setActiveTab] = useState("Overview");
  const data2 = [
    // ----------------------------------1-----------------------------
    {
      label: (<label className='z-0'>Overview</label>),
      value: "Overview",
      desc: (
        <div>
        <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Overview</h1>
        <p>Our virtual CFO services are designed to help small and medium-sized businesses (SMBs) get the financial expertise they need to succeed without the cost of hiring a full-time CFO. Our team of experienced CFOs can help you with a wide range of financial tasks</p>
        </div>
      )
    },
    // -----------------------------2----------------------------
    {
      label: (<label className='z-0'>Virtual CFO Services</label>),
      value: "Virtual CFO Services",
      desc: (
      <div>  
        <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>What Is A Virtual CFO Services ?</h1>
      <p>Virtual chief financial officer (VCFO) services are a type of outsourced financial management service that provides businesses with the expertise of a CFO on a part-time or full-time basis, without the cost of hiring a full-time CFO. VCFOs can help businesses with a wide range of financial tasks, including:</p>
      <ul className='list-disc pl-4'>
        <li>Financial strategy development and implementation</li>
        <li>Budgeting and forecasting</li>
        <li>Financial reporting and analysis</li>
        <li>Cash flow management</li>
        <li>Risk management</li>
        <li>Mergers and acquisitions</li>
      </ul>
      <p>VCFO services are particularly beneficial for small and medium-sized businesses (SMBs) that may not have the resources to hire a full-time CFO. VCFOs can help SMBs to develop and implement sound financial strategies, improve their financial performance, make informed financial decisions, and grow and expand.</p>
      </div> 
      )
    },
    // -------------------------------3-----------------------------
    {
      label: (<label className='z-0'>Benefits for VCFO</label>),
      value: "Benefits for VCFO",
      desc: (
      <div> 
       <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Benefits of VCFO</h1>
        <ul>
          <li>
            <h1 className='font-bold'>Cost savings</h1>
            <p>VCFO services are a cost-effective way to access top-notch financial expertise.</p>
          </li>
          <br/>
          <li>
            <h1 className='font-bold'>Expert financial guidance</h1>
            <p>VCFOs have a wide range of financial skills and experience, covering everything from financial strategy to mergers and acquisitions.</p>
          </li>
          <br/>
          <li>
          <h1 className='font-bold'>Tailored flexibility</h1>
            <p>VCFOs can work with you on a part-time or full-time basis and provide you with ongoing support or project-based services.</p>
          </li>
          <br/>
          <li>
          <h1 className='font-bold'>Scalability</h1>
            <p>VCFO services can scale with your business, so you can always get the support you need.</p>
          </li> 
          <li>
          <h1 className='font-bold'>Valuable network</h1>
            <p>VCFOs often have a network of contacts that can be helpful for your business, including potential investors, lenders, and financial professionals.</p>
          </li> 
        </ul> 
      </div>
      )
    },
      // -------------------------------4-----------------------------
      {
        label: (<label className='z-0'>Benefits for SMBs</label>),
        value: "Benefits for SMBs",
        desc: (
          <div> 
          <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Benefits for SMBs</h1>
           <ul>
             <li>
               <h1 className='font-bold'>Strategic financial development</h1>
               <p>VCFOs can help small and medium-sized businesses (SMBs) develop and execute financial strategies aligned with their business goals.</p>
             </li>
             <br/>
             <li>
               <h1 className='font-bold'>Enhanced financial performance</h1>
               <p>VCFOs can help SMBs improve their financial performance by identifying areas for cost reduction and revenue growth.</p>
             </li>
             <br/>
             <li>
             <h1 className='font-bold'>Informed decision-making</h1>
               <p>VCFOs can provide SMBs with insights for informed financial decisions, offering a clear understanding of financial performance and potential risks.</p>
             </li>
             <br/>
             <li>
             <h1 className='font-bold'>Support for growth and expansion</h1>
               <p>VCFOs can play a crucial role in helping SMBs achieve growth and expansion by providing essential financial guidance.</p>
             </li> 
           </ul> 
         </div>
        )
      },

        // -------------------------------5-----------------------------
    {
      label: (<label className='z-0'>Functions of VCFO services</label>),
      value: "Functions of VCFO services",
      desc: (
        <div> 
          <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Functions of VCFO services</h1>
           <ul>
             <li>
               <h1 className='font-bold'>Financial strategy development and implementation</h1>
               <p>VCFOs can help businesses develop and implement financial strategies aligned with their goals. This may include developing budgets and forecasts, identifying and managing financial risks, and making investment decisions.</p>
             </li>
             <br/>
             <li>
               <h1 className='font-bold'>Financial reporting and analysis</h1>
               <p>VCFOs can help businesses prepare and analyze financial statements, such as income statements, balance sheets, and cash flow statements. This information can help businesses track their financial performance and make informed decisions about their business.</p>
             </li>
             <br/>
             <li>
             <h1 className='font-bold'>Cash flow management</h1>
               <p>VCFOs can help businesses manage their cash flow effectively. This may include forecasting cash flow needs, developing and implementing cash flow management strategies, and negotiating with creditors.</p>
             </li>
             <br/>
             <li>
             <h1 className='font-bold'>Tax planning and compliance</h1>
               <p>VCFOs can help businesses with tax planning and compliance. This may include developing tax strategies, preparing tax returns, and negotiating with tax authorities.</p>
             </li> 
             <li>
             <h1 className='font-bold'>Mergers and acquisitions (M&A)</h1>
               <p>VCFOs can help businesses with M&A transactions. This may include identifying potential targets, evaluating the financial aspects of potential transactions, and developing integration plans.</p>
             </li>
             <li>
             <h1 className='font-bold'>Other financial services</h1>
               <p>VCFOs can also provide various other financial services, such as financial modeling, investor relations, and business valuation.</p>
             </li>
           </ul> 
         </div>
      )
    },

      // -------------------------------6-----------------------------
      {
        label: (<label className='z-0'>Why Govyapar?</label>),
        value: "Why Govyapar?",
        desc: (
          <div> 
          <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Why Govyapar?</h1>
          <p>Govyapar is a leading online legal and financial services platform in India. We offer a variety of services, including virtual CFO services. <br/>Here are some of the reasons why you should choose Govyaparfor your VCFO services:</p>
           <ul>
             <li className=''>
               <h1 className='font-bold'>Expertise:</h1>
               <p>Govyapar has a team of experienced and qualified VCFOs who can help you with various financial tasks.</p>
             </li>
             <br/>
             <li className=''>
               <h1 className='font-bold'>Convenience:</h1>
               <p>Govyapar's VCFO services are available online to access anywhere in India.</p>
             </li>
             <br/>
             <li className=''>
             <h1 className='font-bold'>Affordability:</h1>
               <p>Govyapar's VCFO services are very affordable, especially compared to hiring a full-time CFO.</p>
             </li>
             <br/>
             <li className=''>
             <h1 className='font-bold'>Flexibility:</h1>
               <p>Govyapar offers a variety of VCFO service packages to choose from, so you can choose the one that best meets your needs and budget.</p>
             </li> 
             <li>
             <h1 className='font-bold'>Customer satisfaction:</h1>
               <p>VCFOs can help businesses with M&A transactions. This may include identifying potential targets, evaluating the financial aspects of potential transactions, and developing integration plans.</p>
             </li>
             <li>
             <h1 className='font-bold'>Other financial services</h1>
               <p>Govyapar is known for its excellent customer service. Their team is always available to answer your questions and help you with your needs.</p>
             </li>
           </ul> 
         </div>
        )
      },
  ]

  const [showModal, setShowModal] = useState(false);

// -----------------------------------------services----------------------------------------
  const services = [
    {
      title: 'Finance',
      icon: 'path/to/finance-icon.png',
      items: [
        'Accounting and Bookkeeping',
        'MIS Reporting',
        'Tax Compliances Management',
        'Financial Strategy Development',
        'Budgeting and Financial Forecasting',
        'Cash Flow Management',
        'Risk Management and Compliance',
        'Investment and Fundraising Assistance',
        'Financial Reporting and Analysis',
      ],
    },
    {
      title: 'Legal',
      icon: 'path/to/legal-icon.png',
      items: [
        'Drafting Legal Agreements',
        'Ongoing corporate governance',
        'Preparation and negotiation of contracts',
        'Guidance on contracts and policies',
        'Handling disputes, and compliance',
        'Registration and protection of trademarks',
        'Advising on industry-specific regulatory requirement',
        'Assistance with regulatory filings',
      ],
    },
    {
      title: 'Secretarial',
      icon: 'path/to/secretarial-icon.png',
      items: [
        'Term Sheet/ SHA',
        'Due Diligence Handling',
        'Investor Relationship Management',
        'ESOPs Implementation',
        'Corporate Compliance',
        'Board Meeting Management',
        'Document Preparation and Filing',
        'Shareholder Communication',
        'Corporate Governance Advisory',
        'Entity Formation and Dissolution',
      ],
    },
  ];

  // -----------------------------------------------------------
  const benefits = [
    {
      icon: "📊",
      title: "Cost-Effective Expertise",
      description:
        "Access to high-level financial expertise without the expense of a full-time CFO, making it affordable for small and medium-sized businesses.",
    },
    {
      icon: "📈",
      title: "Strategic Financial Planning",
      description:
        "Benefit from advanced financial strategies and planning to support long-term business growth and sustainability.",
    },
    {
      icon: "💵",
      title: "Improved Cash Flow Management",
      description:
        "Enhance cash flow management practices to ensure liquidity and financial stability, helping the business meet its obligations.",
    },
    {
      icon: "📚",
      title: "Informed Decision-Making",
      description:
        "Receive detailed financial analyses and insights, enabling better-informed business decisions that drive profitability and growth.",
    },
    {
      icon: "⚠️",
      title: "Risk Mitigation",
      description:
        "Implement effective risk management strategies to identify, assess, and mitigate financial risks, safeguarding the business's future.",
    },
    {
      icon: "⚙️",
      title: "Flexibility and Scalability",
      description:
        "Enjoy the flexibility to scale services up or down based on your business's needs, ensuring you only pay for what you need when you need it.",
    },
  ];

  // ------------------------------------Industries------------------------------------------------
  const industries = [
    { icon: '🏭', title: 'Manufacturing', bgColor: 'bg-blue-100' },
    { icon: '🏡', title: 'Real Estate', bgColor: 'bg-yellow-100' },
    { icon: '🌱', title: 'Agriculture', bgColor: 'bg-green-100' },
    { icon: '🏨', title: 'Hotel & Restaurants', bgColor: 'bg-gray-100' },
    { icon: '🚚', title: 'Shipping & Logistics', bgColor: 'bg-pink-100' },
    { icon: '🏥', title: 'Healthcare', bgColor: 'bg-purple-100' },
    { icon: '🛒', title: 'Retail and E-Commerce', bgColor: 'bg-blue-100' },
    { icon: '🏬', title: 'Small Business Accounting', bgColor: 'bg-gray-300' },
    { icon: '🌐', title: 'NGOs and Foundation', bgColor: 'bg-gray-100' },
  ];

  return (
    <>
    <div className=''>
    <a href='https://wa.me/919808030923'>
    <img src='WhatsappIcon.png' className='fixed w-[28%] md:w-[13%] lg:w-[10%] right-0 top-[520px] md:top-[470px] z-10' alt=''/></a>
     <Link to ="/scheduleCall">
     <button className='text-white bg-yellow-600 border-2 border-black p-2 rounded-full cursor-pointer z-20 fixed w-[40%] sm:w-[20%] md:w-[24%] lg:w-[20%] xl:w-[16%] right-3 top-[520px] md:top-[470px] overflow-hidden mt-16 sm:mt-24 xl:mt-24 text-xs md:text-lg'>
      <i class="fa fa-clock text-white text-xs md:text-lg"></i> Schedule a Call</button>
     </Link>
    </div>

       {/* -------------------------------------Mobile devices Virtual CFO-------------------------------------/ */}
   <div className='max-w-full mx-0 px-8 block md:hidden pt-32'>
 <div class="relative flex flex-col text-gray-700 bg-[#C0C0C0] shadow-xl z-[-1]">
  <div class="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-clip-border">
  </div>
  <div class="p-6 text-center">
    
    <p>
    <form className='py-4 text-center'>
        <label className='pt-2'>Email</label><br/>
        <input type='email'
         placeholder='Enter your Email'
         className='rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>Mobile Number</label><br/>
        <input type='tel'
        placeholder="Enter Phone Number" 
        maxLength={10}
         className='rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>City/Pincode</label><br/>
        <input type=''
         placeholder='Enter your City/Pincode'
         className='rounded-sm p-1 border-1'/>
         <br/>
      </form>
    </p>
  </div>
  <div class="flex justify-center p-6 py-2 mb-4 gap-7">
  <button className='items-center bg-black text-white p-2 rounded-md'>Get Started</button>
  </div>
</div>
</div>
<div className=' bg-[#E0E0E0] relative bottom-60 z-[-2] pb-10 pt-80 px-4 block md:hidden'>
  <h1 className='text-2xl font-extrabold'>Virtual CFO</h1>
  <h2 className="py-6 text-2xl font-semibold">
  <ul className='text-lg md:text-xl space-y-2 pb-3 text-justify md:text-left py-2 pl-2 md:pl-10 lg:pl-16 xl:pl-28'>
  <li>
        <i class="fas fa-check-square text-yellow-500"></i> Hire an expert CFO online and avail 360 support for all your company need
      </li>
      
      <li>
        <i class="fas fa-check-square text-yellow-500"></i> Our expert specialize in Mergers and acquisition, Financial advice planning, Compliance support and many more.
      </li>
  </ul>
  </h2> 
</div>

{/* ------------------------------------------------------MEDIUM AND LARGE SCREN- Virtual CFO--------------------------------- */}
<div className="w-full mx-0 md:block hidden md:pt-28 pt-12">
  <div className='bg-[#E0E0E0] float-left w-[45%] h-screen pl-10 lg:pl-32 pt-24 xl:pl-52 2xl:pl-80'>
  <div class="relative flex flex-col text-gray-700 bg-[#C0C0C0] shadow-xl left-8 lg:left-12">
  <div class="relative mt-4 overflow-hidden text-gray-700 bg-clip-border">
  </div>
  <div class="p-6 text-center">
  
    <p>
    <form className='py-4 text-center hidden md:block'>
        <label className='pt-2'>Email</label><br/>
        <input type='email'
         placeholder='Enter your Email'
         className='rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>Mobile Number</label><br/>
        <input type='tel'
        placeholder="Enter Phone Number" 
        maxLength={10}
         className='rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>City/Pincode</label><br/>
        <input type=''
         placeholder='Enter your City/Pincode'
         className='rounded-sm p-1 border-1'/>
         <br/>
      </form>
    </p>
  </div>
  <div class="flex justify-center p-6 py-2 mb-4 gap-7">
  <button className='items-center bg-black text-white p-2 rounded-md'>Get Started</button>
  </div>
</div>
  </div>

  <div className='bg-white h-screen px-10 pt-3 pr-28 lg:pt-16 lg:pr-32 xl:pr-52 2xl:pr-80'>
  <div className='relative bottom-56 pb-10 md:pt-[340px] lg:pt-[310px] xl:pt-[] px-7 left-10 lg:left-20 lg:px-10 overflow-hidden'>
  <h1 className='md:text-3xl lg:text-4xl xl:text-6xl font-extrabold'>Virtual CFO</h1>
  <h2 className="py-6 text-2xl font-semibold">
  <ul className='text-lg md:text-xl space-y-2 pb-3 text-justify md:text-left py-2'>
      <li>
        <i class="fas fa-check-square text-yellow-500"></i> Hire an expert CFO online and avail 360 support for all your company need
      </li>
      
      <li>
        <i class="fas fa-check-square text-yellow-500"></i> Our expert specialize in Mergers and acquisition, Financial advice planning, Compliance support and many more.
      </li>
  </ul>
  </h2>

</div>
  </div>
</div>

{/* ---------------------------------------What is Virtual CFO?----------------------------------------------- */}
<div className='md:grid md:grid-rows-1 md:grid-cols-2 relative bottom-20 md:bottom-0'>
 <img src='What is Virtual CFO.png' className='xl:w-[80%]'/>
<div className="max-w-5xl mx-auto p-8">
    <h2 className="text-3xl md:text-4xl xl:text-6xl font-bold text-black my-4">What is Virtual CFO?</h2>
        <p className="mb-4 text-justify">
        A Virtual CFO is an external financial expert who provides high-level financial management and strategic advisory services to businesses on a part-time, contractual, or as-needed basis. This service is particularly beneficial for companies that require expert financial guidance without the expense of a full-time CFO. A Virtual CFO helps businesses make informed decisions, improve financial health, and achieve their growth objectives.
        </p>
        <ul className="list-disc space-y-4">
          <li><p><span className='font-bold'>Cost-Effective:</span> Access to high-level financial expertise without the cost of a full-time executive.</p></li>
           
          <li><p><span className='font-bold'>Flexibility: </span> Services can be scaled up or down based on the business's needs.</p></li>

          <li><p><span className='font-bold'>Expert Guidance: </span> Benefit from the knowledge and experience of seasoned financial professionals.</p></li>

          <li><p><span className='font-bold'>Strategic Focus: </span> Helps businesses make informed decisions that drive growth and profitability.</p></li>
        </ul>
        <button
          className="mt-10 bg-yellow-600 border-black border-2 text-white px-6 py-2 rounded-full hover:bg-gray-900 transition duration-200"
          onClick={() => setShowModal(true)}
        >
          Book your Appointment
        </button>
        <ModalCFO showModal={showModal} setShowModal={setShowModal} /> {/* Include the modal component */}
        
</div>
</div>

{/* ----------------------- Over 15 Years of Expertise in Finance and Legal-------------------------------------------- */}
<div className="max-w-6xl mx-auto p-8 relative bottom-10 md:bottom-0">
      <h1 className="text-2xl md:text-4xl font-bold text-black mb-6 text-center">
        Over 15 Years of Expertise in Finance and Legal
      </h1>
      <p className="text-lg mb-12 text-gray-700 text-justify">
        Govyapar is a trusted leader in providing top-tier Virtual CFO services to businesses seeking financial expertise and strategic guidance. With 15+ years of experience in the financial sector, our mission is to empower companies with the insights and tools they need to thrive in a competitive marketplace. We understand that every business is unique, which is why we tailor our services to meet the specific needs of each client.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <img src="Financial Strategy and Planning.png" alt="Financial Strategy and Planning" className="mx-auto mb-4 w-[40%]"/>
          <h3 className="text-xl font-semibold text-yellow-700 mb-2">Financial Strategy and Planning</h3>
          <p className="text-gray-600">We develop customized financial strategies that align with your business objectives.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <img src="Budgeting and Forecasting.png" alt="Budgeting and Forecasting" className="mx-auto mb-4 w-[40%]"/>
          <h3 className="text-xl font-semibold text-yellow-700 mb-2">Budgeting and Forecasting</h3>
          <p className="text-gray-600">Our experts create accurate budgets and forecasts, providing you with a clear financial roadmap to guide.</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <img src="Financial Reporting and Analysis.png" alt="Financial Reporting and Analysis" className="mx-auto mb-4 w-[40%]"/>
          <h3 className="text-xl font-semibold text-yellow-700 mb-2">Financial Reporting and Analysis</h3>
          <p className="text-gray-600">We prepare comprehensive financial reports and analyses, offering valuable insights.</p>
        </div>
      </div>
      <div className="text-center mt-16">
        <h2 className="text-4xl font-bold text-yellow-700">1000+</h2>
        <p className="text-lg text-gray-700">Startups working with us</p>
        <img src="Over 15 Years of Expertise in Finance and Legal.jpg" alt="Graph" className="mx-auto mt-4 h-40 w-52"/>
      </div>
    </div>

{/* -------------------------------Complete Specification Application Process------------------------ */}
<div className="md:bg-gray-100 py-12 relative bottom-10 md:bottom-0">
<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
<h2 className="text-2xl md:text-3xl font-extrabold text-gray-900 mb-8 text-center">
          Complete Specification Application <span className="text-yellow-600">Process</span>
        </h2>
<ul className="grid grid-cols-1 grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-4">

<li class="bg-white rounded-lg shadow-lg p-6 flex flex-col items-centercursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105">
  <i class="fa-solid fa-headset text-center text-7xl text-yellow-500"></i>
  <h1 className='text-xl font-semibold text-gray-900 text-center py-2'>Consult with our VCFO Specialist</h1>
  <p className='text-black text-md text-justify'>Initiate a conversation with our seasoned VCFO specialist, who can offer valuable insights and address any queries about your financial strategy.</p>
</li>

<li class="bg-white rounded-lg shadow-lg p-6 flex flex-col items-centercursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105">
<i className='fas fa-file-alt text-center text-7xl text-yellow-500'/>
  <h1 className='text-xl font-semibold text-gray-900 text-center py-2'>Assemble your financial records</h1>
  <p className='text-black text-md text-justify'>Ensure all essential financial documents are compiled and well-organized before sharing them with our team.</p>
</li>

<li class="bg-white rounded-lg shadow-lg p-6 flex flex-col items-centercursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105">
<i className='far fa-file-alt text-center text-7xl text-yellow-500'/>
  <h1 className='text-xl font-semibold text-gray-900 text-center py-2'>Financial Strategy Implementation</h1>
  <p className='text-black text-md text-justify'>Our experts will oversee the execution of your financial strategy, taking charge of the necessary paperwork and ensuring that you are continually informed about the progress of your financial plans.</p>
</li>
</ul>
</div>
</div>

{/* -------------------------------------side navbar---------------- */}
<div className='px-28 py-10 hidden md:block'>
{/* <Tabs value={activeTab}
className="grid grid-cols-2 grid-rows-1">
  
      <TabsHeader
        className="rounded-md bg-white shadow-md border-1 border-black pt-10 z-0 flex-col md:w-[50%]"
        indicatorProps={{
          className:
            "bg-transparent shadow-none rounded-none",
        }}
      >
        {data2.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            className={activeTab === value ? "text-yellow-500 text-xl font-bold bg-black rounded-md" : ""}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody
      className='relative right-20 lg:right-32 text-justify'>
        {data2.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs> */}
    {/* <form className='py-4 text-center'>
        <label className='pt-2'>Email</label><br/>
        <input type='email'
         placeholder='Enter your Email'
         className='rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>Mobile Number</label><br/>
        <input type='tel'
        placeholder="Enter Phone Number" 
        maxLength={10}
         className='rounded-sm p-1 border-1'/>
        <br/>
        <label className='pt-2'>City/Pincode</label><br/>
        <input type=''
         placeholder='Enter your City/Pincode'
         className='rounded-sm p-1 border-1'/>
         <br/>
      </form> */}
</div>

{/* -------------------------------side navbar phone-------------------- */}
<div className='pt-20 px-4 lg:px-20 cursor-pointer block md:hidden'>
      {/* ---------------------------Overview------- */}
     <div>
        <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Overview</h1>
        <p>Our virtual CFO services are designed to help small and medium-sized businesses (SMBs) get the financial expertise they need to succeed without the cost of hiring a full-time CFO. Our team of experienced CFOs can help you with a wide range of financial tasks</p>
      </div>
      <br/>

{/* ----------------------------------------What Is A Virtual CFO Services ?---------------------- */}
<div>  
        <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>What Is A Virtual CFO Services ?</h1>
      <p>Virtual chief financial officer (VCFO) services are a type of outsourced financial management service that provides businesses with the expertise of a CFO on a part-time or full-time basis, without the cost of hiring a full-time CFO. VCFOs can help businesses with a wide range of financial tasks, including:</p>
      <ul className='list-disc pl-4'>
        <li>Financial strategy development and implementation</li>
        <li>Budgeting and forecasting</li>
        <li>Financial reporting and analysis</li>
        <li>Cash flow management</li>
        <li>Risk management</li>
        <li>Mergers and acquisitions</li>
      </ul>
      <p>VCFO services are particularly beneficial for small and medium-sized businesses (SMBs) that may not have the resources to hire a full-time CFO. VCFOs can help SMBs to develop and implement sound financial strategies, improve their financial performance, make informed financial decisions, and grow and expand.</p>
      </div> 
      <br/>
      {/* ---------------------------------Benefits of VCFO----------------------------- */}
      <div> 
       <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Benefits of VCFO</h1>
        <ul>
          <li>
            <h1 className='font-bold'>Cost savings</h1>
            <p>VCFO services are a cost-effective way to access top-notch financial expertise.</p>
          </li>
          <br/>
          <li>
            <h1 className='font-bold'>Expert financial guidance</h1>
            <p>VCFOs have a wide range of financial skills and experience, covering everything from financial strategy to mergers and acquisitions.</p>
          </li>
          <br/>
          <li>
          <h1 className='font-bold'>Tailored flexibility</h1>
            <p>VCFOs can work with you on a part-time or full-time basis and provide you with ongoing support or project-based services.</p>
          </li>
          <br/>
          <li>
          <h1 className='font-bold'>Scalability</h1>
            <p>VCFO services can scale with your business, so you can always get the support you need.</p>
          </li> 
          <li>
          <h1 className='font-bold'>Valuable network</h1>
            <p>VCFOs often have a network of contacts that can be helpful for your business, including potential investors, lenders, and financial professionals.</p>
          </li> 
        </ul> 
      </div>
      <br/>

      {/* -----------------------------------------Benefits for SMBs---------------- */}
      <div> 
          <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Benefits for SMBs</h1>
           <ul>
             <li>
               <h1 className='font-bold'>Strategic financial development</h1>
               <p>VCFOs can help small and medium-sized businesses (SMBs) develop and execute financial strategies aligned with their business goals.</p>
             </li>
             <br/>
             <li>
               <h1 className='font-bold'>Enhanced financial performance</h1>
               <p>VCFOs can help SMBs improve their financial performance by identifying areas for cost reduction and revenue growth.</p>
             </li>
             <br/>
             <li>
             <h1 className='font-bold'>Informed decision-making</h1>
               <p>VCFOs can provide SMBs with insights for informed financial decisions, offering a clear understanding of financial performance and potential risks.</p>
             </li>
             <br/>
             <li>
             <h1 className='font-bold'>Support for growth and expansion</h1>
               <p>VCFOs can play a crucial role in helping SMBs achieve growth and expansion by providing essential financial guidance.</p>
             </li> 
           </ul> 
         </div>
         <br/>

   {/* -------------------------------------Functions of VCFO services----------------- */}
   <div> 
          <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Functions of VCFO services</h1>
           <ul>
             <li>
               <h1 className='font-bold'>Financial strategy development and implementation</h1>
               <p>VCFOs can help businesses develop and implement financial strategies aligned with their goals. This may include developing budgets and forecasts, identifying and managing financial risks, and making investment decisions.</p>
             </li>
             <br/>
             <li>
               <h1 className='font-bold'>Financial reporting and analysis</h1>
               <p>VCFOs can help businesses prepare and analyze financial statements, such as income statements, balance sheets, and cash flow statements. This information can help businesses track their financial performance and make informed decisions about their business.</p>
             </li>
             <br/>
             <li>
             <h1 className='font-bold'>Cash flow management</h1>
               <p>VCFOs can help businesses manage their cash flow effectively. This may include forecasting cash flow needs, developing and implementing cash flow management strategies, and negotiating with creditors.</p>
             </li>
             <br/>
             <li>
             <h1 className='font-bold'>Tax planning and compliance</h1>
               <p>VCFOs can help businesses with tax planning and compliance. This may include developing tax strategies, preparing tax returns, and negotiating with tax authorities.</p>
             </li> 
             <li>
             <h1 className='font-bold'>Mergers and acquisitions (M&A)</h1>
               <p>VCFOs can help businesses with M&A transactions. This may include identifying potential targets, evaluating the financial aspects of potential transactions, and developing integration plans.</p>
             </li>
             <li>
             <h1 className='font-bold'>Other financial services</h1>
               <p>VCFOs can also provide various other financial services, such as financial modeling, investor relations, and business valuation.</p>
             </li>
           </ul> 
         </div>
         <br/>

   {/* --------------------------------------Why Govyapar?------------------------------- */}
        <div> 
          <h1 className='text-lg md:text-2xl text-yellow-500 font-semibold'>Why Govyapar?</h1>
          <p>Govyapar is a leading online legal and financial services platform in India. We offer a variety of services, including virtual CFO services. <br/>Here are some of the reasons why you should choose Govyaparfor your VCFO services:</p>
           <ul>
             <li className='flex space-x-2'>
               <h1 className='font-bold'>Expertise:</h1>
               <p>Govyapar has a team of experienced and qualified VCFOs who can help you with various financial tasks.</p>
             </li>
             <br/>
             <li className='flex space-x-2'>
               <h1 className='font-bold'>Convenience:</h1>
               <p>Govyapar's VCFO services are available online to access anywhere in India.</p>
             </li>
             <br/>
             <li className='flex space-x-2'>
             <h1 className='font-bold'>Affordability:</h1>
               <p>Govyapar's VCFO services are very affordable, especially compared to hiring a full-time CFO.</p>
             </li>
             <br/>
             <li className='flex space-x-2'>
             <h1 className='font-bold'>Flexibility:</h1>
               <p>Govyapar offers a variety of VCFO service packages to choose from, so you can choose the one that best meets your needs and budget.</p>
             </li> 
             <li>
             <h1 className='font-bold'>Customer satisfaction:</h1>
               <p>VCFOs can help businesses with M&A transactions. This may include identifying potential targets, evaluating the financial aspects of potential transactions, and developing integration plans.</p>
             </li>
             <li>
             <h1 className='font-bold'>Other financial services</h1>
               <p>Govyapar is known for its excellent customer service. Their team is always available to answer your questions and help you with your needs.</p>
             </li>
           </ul> 
         </div>
</div>

{/* -------------------------------------Perks of using a virtual cfo--------------------- */}
<div className="py-16">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900">Perks of Using a Virtual CFO</h2>
          <p className="mt-4 text-lg text-gray-600 text-justify">
            A Virtual CFO plays an important role in today's business by providing essential financial expertise and strategic guidance without the commitment of a full-time executive. This outsourced model not only reduces costs but also brings specialized knowledge that can significantly enhance financial performance and operational efficiency, ultimately positioning the business for sustainable growth and success in a competitive market.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-6">
              <div className="text-5xl text-blue-500 mb-4">{benefit.icon}</div>
              <h3 className="text-xl font-semibold text-gray-800">{benefit.title}</h3>
              <p className="mt-2 text-gray-600">{benefit.description}</p>
            </div>
          ))}
        </div>
        <div className="text-center mt-12">
        <button
          className="mt-10 bg-gray-950 text-white px-6 py-2 rounded-full hover:bg-gray-900 transition duration-200"
          onClick={() => setShowModal(true)}
        >
          Book your Appointment
        </button>
        <ModalCFO showModal={showModal} setShowModal={setShowModal} /> {/* Include the modal component */}
        </div>
      </div>
    </div>

     {/* -------------------------------------Services We Provide-------------------------------------------- */}
    <div className="bg-gray-100 py-16">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-3xl font-bold mb-6">Services We Provide</h2>
        <p className="mb-10">
          At Govyapar we offer a comprehensive range of Virtual CFO services designed to meet the unique financial needs of your business. Our expertise and personalized approach ensure that you receive the highest level of support and strategic guidance. Our key services include:
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg">
              <div className="bg-yellow-500 border-1 border-black text-white p-6 rounded-t-lg">
                <h3 className="text-xl font-bold">{service.title}</h3>
              </div>
              <div className="p-6 text-left">
                <ul>
                  {service.items.map((item, idx) => (
                    <li key={idx} className="mb-2 flex items-center">
                      <i className="fas fa-check-circle text-yellow-500 mr-2"></i> {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <button
          className="mt-10 bg-gray-950 text-white px-6 py-2 rounded-full hover:bg-gray-900 transition duration-200"
          onClick={() => setShowModal(true)}
        >
          Enquiry Now
        </button>
        <ModalCFO showModal={showModal} setShowModal={setShowModal} /> {/* Include the modal component */}
      </div>
    </div>

    
{/* ----------------------------------------OUR SERVICES----------------------------------------- */}
<div className='bg-gradient-to-t from-black via-gray-400 to-black py-10 container max-w-full'>
<motion.section
initial={{y:100, opacity:0}}
whileInView={{y:0, opacity:1}}
transition={{delay:0.2, y:{type:"spring", stiffness:60}, opacity:{duration:0.2}, ease:"easeIn", duration:1}}
className='pt-10 text-center scroll-smooth overflow-hidden'>
<h3 className='text-xl font-semibold text-yellow-500'>OUR SERVICES</h3>
<h1 className='text-3xl font-bold text-black leading-tight'>What We Offer</h1>
</motion.section>

{/* ------------------------------------------Packages------------------------------------------------------- */}
<section className='container max-w-full lg:mx-20 md:mx-20 lg:px-32 xl:px-60 mx-0 pt-10 scroll-smooth'>
<motion.h3 className='text-xl font-semibold text-yellow-500 text-center'>PACKAGES</motion.h3>

<motion.div className="pricing-box-container grid md:grid-cols-3 grid-rows-1 justify-center">
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{
          delay: 0.2,
          y: { type: "spring", stiffness: 60 },
          opacity: { duration: 0.2 },
          ease: "easeIn",
          duration: 1,
        }}
        className="pricing-box bg-yellow-500 text-center shadow-md rounded-md p-6 m-2 min-w-sm max-w-md h-auto flex flex-col justify-between"
      >
        <div>
          <h5 className="text-lg font-bold text-black uppercase">STARTER</h5>
          <ul className="features-list list-none pl-0 mt-4">
            <li className="text-left text-black mb-2">
              <i className="fas fa-check-circle mr-2 text-black"></i> Preparation of books of accounts
            </li>
            <li className="text-left text-black mb-2">
              <i className="fas fa-check-circle mr-2 text-black"></i> Preparation of Trial Balance, Balance <br />Sheet, Profit & Loss Statement
            </li>
            <li className="text-left text-black mb-2">
              <i className="fas fa-check-circle mr-2 text-black"></i> Income Tax Returns
            </li>
          </ul>
        </div>
        <button className="btn-primary text-white bg-black hover:bg-black hover:shadow-md active:bg-black focus:bg-black focus:border-white px-4 py-2 rounded-md font-bold mt-10 uppercase transition ease-in-out duration-100 w-[60%] lg:w-[80%] mx-auto">
          Lets Start
        </button>
      </motion.div>

      <motion.div
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{
          delay: 0.2,
          y: { type: "spring", stiffness: 60 },
          opacity: { duration: 0.4 },
          ease: "easeIn",
          duration: 1,
        }}
        className="pricing-box bg-yellow-500 text-center shadow-md rounded-md p-6 m-2 min-w-sm max-w-md h-auto flex flex-col justify-between"
      >
        <div>
          <h5 className="text-lg font-bold text-black uppercase">Starter</h5>
          <ul className="features-list list-none pl-0 mt-4">
            <li className="text-left text-black mb-2">
              <i className="fas fa-check-circle mr-2 text-black"></i> Preparation of books of accounts
            </li>
            <li className="text-left text-black mb-2">
              <i className="fas fa-check-circle mr-2 text-black"></i> Preparation of Trial Balance, Balance <br />Sheet, Profit & Loss Statement
            </li>
            <li className="text-left text-black mb-2">
              <i className="fas fa-check-circle mr-2 text-black"></i> GST Returns Income Tax Returns
            </li>
          </ul>
        </div>
        <button className="btn-primary text-white bg-black hover:bg-black hover:shadow-md active:bg-black focus:bg-black focus:border-white px-4 py-2 rounded-md font-bold mt-10 uppercase transition ease-in-out duration-100 w-[60%] lg:w-[80%] mx-auto">
          Lets Start
        </button>
      </motion.div>

      <motion.div
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{
          delay: 0.2,
          y: { type: "spring", stiffness: 60 },
          opacity: { duration: 0.4 },
          ease: "easeIn",
          duration: 1,
        }}
        className="pricing-box bg-yellow-500 text-center shadow-md rounded-md p-6 m-2 min-w-sm max-w-md h-auto flex flex-col justify-between"
      >
        <div>
          <h5 className="text-lg font-bold text-black uppercase">All In One</h5>
          <ul className="features-list list-none pl-0 mt-4">
            <li className="text-left text-black mb-2">
              <i className="fas fa-check-circle mr-2 text-black"></i> Preparation of books of accounts
            </li>
            <li className="text-left text-black mb-2">
              <i className="fas fa-check-circle mr-2 text-black"></i> Preparation of Trial Balance, Balance <br />Sheet, Profit & Loss Statement
            </li>
            <li className="text-left text-black mb-2">
              <i className="fas fa-check-circle mr-2 text-black"></i> GST Returns Income Tax Returns <br />ROC Compliances
            </li>
          </ul>
        </div>
        <button className="btn-primary text-white bg-black hover:bg-black hover:shadow-md active:bg-black focus:bg-black focus:border-white px-4 py-2 rounded-md font-bold mt-10 uppercase transition ease-in-out duration-100 w-[60%] lg:w-[80%] mx-auto">
          Lets Start
        </button>
      </motion.div>
    </motion.div>

</section>
</div>

    {/* ----------------------------------------------Industries----------------------------------- */}
    <div className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Industries</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {industries.map((industry, index) => (
            <div
              key={index}
              className={`flex flex-col items-center p-6 ${industry.bgColor} rounded-lg shadow-md`}
            >
              <div className="text-5xl mb-4">{industry.icon}</div>
              <h3 className="text-lg font-semibold text-gray-800">{industry.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>

  {/* ---------------------------------Testimonials---------------------------- */}
   {/* <!-- Container for demo purpose --> */}
   <div class="container mt -20 mx-auto md:px-6">
  <section class="mb-32 text-center">
   <h1 className='text-2xl text-yellow-500 md:text-4xl text-center font-bold md:py-16'>Testimonials </h1>

    <div class="grid gap-x-6 md:grid-cols-3 lg:gap-x-12 max-w-5xl mx-auto">
      <div class="mb-12 md:mb-0 shadow-sm border-1 border-gray-200 py-4 rounded-lg">
        <div class="mb-6 flex justify-center">
          <img src="employee.png"
            class="w-32 rounded-full shadow-lg dark:shadow-black/20"  alt=''/>
        </div>
        <h5 class="mb-2 text-lg font-bold">Maria Smantha</h5>
        <h6 class="mb-4 font-bold text-yellow-600 dark:text-primary-400">
          CA
        </h6>
        <p class="mb-4">
        GoVyapar simplified my taxes! Saved time & money.
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="inline-block w-6">
            <path fill="currentColor"
              d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
          </svg>
        </p>
        <ul class="mb-0 flex justify-center">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m480 757 157 95-42-178 138-120-182-16-71-168v387ZM233 976l65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
        </ul>
      </div>

      <div class="mb-12 md:mb-0 shadow-sm border-1 border-gray-200 py-4 rounded-lg">
        <div class="mb-6 flex justify-center">
          <img src="employee.png"
            class="w-32 rounded-full shadow-lg dark:shadow-black/20"  alt=''/>
        </div>
        <h5 class="mb-2 text-lg font-bold">Lisa Cudrow</h5>
        <h6 class="mb-4 font-bold text-yellow-600 dark:text-primary-400">
          Software Developer
        </h6>
        <p class="mb-4">
        GoVyapar helped navigate my complex tax situation. Maximized deductions!
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="inline-block w-6">
            <path fill="currentColor"
              d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
          </svg>
        </p>
        <ul class="mb-0 flex justify-center">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
        </ul>
      </div>

      <div class="mb-0 shadow-sm border-1 border-gray-200 py-4 rounded-lg">
        <div class="mb-6 flex justify-center">
          <img src="employee.png"
            class="w-32 rounded-full shadow-lg dark:shadow-black/20"  alt=''/>
        </div>
        <h5 class="mb-2 text-lg font-bold">John Smith</h5>
        <h6 class="mb-4 font-bold text-yellow-600 dark:text-primary-400">
          CA
        </h6>
        <p class="mb-4">
          GoVyapar keeps me informed & saves me money. Trusted partner!
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="inline-block w-6">
            <path fill="currentColor"
              d="M580 556h160V396H580v160Zm-360 0h160V396H220v160Zm406 220 80-160H520V336h280v288l-76 152h-98Zm-360 0 80-160H160V336h280v288l-76 152h-98Zm34-300Zm360 0Z" />
          </svg>
        </p>
        <ul class="mb-0 flex justify-center">
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
              <path fill="currentColor"
                d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
            </svg>
          </li>
        </ul>
      </div>
    </div>
  </section>
</div>

{/* ---------------------------------------------------FAQS------------------------------------------------------- */}
<section class=" text-black py-32 min-h-screen">
  <div class="container flex flex-col justify-center p-4 mx-auto md:p-8">
    <h2 class="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">Frequently Asked Questions</h2>
    <div class="flex flex-col divide-y sm:px-8 lg:px-20 xl:px-32 divide-yellow-600">
      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What is a virtual CFO?</summary>
        <div class="px-4 pb-4 text-justify">
          <p>A virtual CFO (VCFO) is a financial expert who provides outsourced CFO services to businesses of all sizes. VCFOs typically work remotely, providing their clients with on-demand access to their expertise and guidance.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What does a virtual CFO do?</summary>
        <div class="px-4 pb-4 text-justify">
          <p>VCFOs can provide a wide range of services, including:Financial reporting and analysis
            <ul className='list-disc text-justify'>
              <li>Financial reporting and analysis</li>
              <li>Budgeting and forecasting</li>
              <li>Cash flow management</li>
              <li>Strategic financial planning</li>
              <li>Risk management</li>
              <li>Investor relations</li>
              <li>Mergers and acquisitions</li>
              <li>Tax planning and compliance</li>
            </ul>
          </p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How do you become a virtual CFO?</summary>
        <div class="px-4 pb-4 text-justify">
          <p>To become a VCFO, you typically need to have a bachelor's degree in accounting or finance, as well as several years of experience working as an accountant or CFO. You may also want to consider pursuing a professional certification, such as the Certified Public Accountant (CPA) or Certified Management Accountant (CMA) designation.
            </p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How much does a virtual CFO cost?</summary>
        <div class="px-4 pb-4">
          <p>The cost of virtual CFO services can vary depending on a number of factors, including the size and complexity of your business, the level of services you need, and the experience of the VCFO you hire. However, VCFO services are typically much less expensive than hiring a full-time CFO.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How much can I expect to pay a virtual CFO?</summary>
        <div class="px-4 pb-4">
          <p>Depending on your requirements, our experts will share the charges.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Why should you hire a virtual CFO?</summary>
        <div class="px-4 pb-4">
          <p>There are a number of reasons why you might want to hire a virtual CFO, including:
          <ul className='list-disc text-justify'>
            <li>To save money on the cost of hiring a full-time CFO</li>
            <li>To get access to the expertise and guidance of a seasoned financial expert</li>
            <li>To free up your time to focus on other aspects of your business</li>
            <li>To get help with specific financial tasks, such as budgeting, forecasting, or cash flow management</li>
            <li>To get support with strategic financial planning and decision-making</li>
            </ul>
          </p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What are CFO services?</summary>
        <div class="px-4 pb-4">
          <p>CFO services are a wide range of financial management services that are typically provided by a chief financial officer (CFO). CFO services can include financial reporting and analysis, budgeting and forecasting, cash flow management, strategic financial planning, risk management, investor relations, and mergers and acquisitions.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What are the roles and responsibilities of a CFO in a startup?</summary>
        <div class="px-4 pb-4">
          <p>The roles and responsibilities of a CFO in a startup can vary depending on the size and stage of the company. However, some common duties include:
          <ul className='list-disc text-justify'>
            <li>Developing and implementing financial strategies</li>
            <li>Managing the company's budget and finances</li>
            <li>Overseeing the company's accounting and financial reporting functions</li>
            <li>Providing financial advice and guidance to the company's CEO and other executives</li>
            <li>Raising capital and managing investor relations</li>
            </ul>
          </p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What is the hourly rate for a virtual CFO?</summary>
        <div class="px-4 pb-4">
          <p>Get in touch with our expert today!</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What is the hourly rate for a part-time CFO?</summary>
        <div class="px-4 pb-4">
          <p>The hourly rate for a part-time CFO can also vary depending on a number of factors, but it is typically higher than the hourly rate for a full-time CFO. This is because part-time CFOs typically have more experience and expertise than full-time CFOs. Get in touch with our expert today!</p>
        </div>
      </details>

    </div>
  </div>
</section>

       {/* -----------------------------------------------Subscribe to our newsletter------------------------------------------------ */}
       <div class="container bg-black mt-20 pt-10 max-w-full scroll-smooth">
  <div class="md:flex justify-between items-center lg:px-28 py-10 ">
    <motion.div
     initial={{x:-100, opacity:0}}
     whileInView={{x:0, opacity:1}}
     transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
     >
      <h1 class="md:text-4xl text-2xl text-yellow-500 font-bold  sm:text-center md:text-left leading-tight">Subscribe to us!</h1>
      <p class="text-md text-white my-2">Subscribe to our newsletter for exclusive updates and offers.</p>
    </motion.div>

    <form ref={form} onSubmit={sendEmail}>
      <motion.div  initial={{x:-100, opacity:0}}
      whileInView={{x:0, opacity:1}}
      transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
      class="flex">
        <input 
        type="email" 
        placeholder="Enter your email" 
        name="user_email" 
        required 
        class="rounded-md px-4 py-2 w-full mr-2 focus:outline-none focus:ring-1 focus:ring-yellow-500" />
        <input 
        type="submit" 
        value="Subscribe" 
        class="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded-md shadow-sm"/>

      </motion.div>
    </form>
  </div>

    {/* ----------------------------------------------------------Contact------------------------------------------------------- */}
<div>
  <h1 className="text-2xl text-yellow-500 font-bold text-center">Contact</h1>
  <motion.form method='POST' 
   initial={{y:-100, opacity:0}}
   whileInView={{y:0, opacity:1}}
   transition={{delay:0.10, y:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
    className="text-xl space-y-1 rounded-md mx-3 md:pt-10 pt-2 text-center sm:px-32 md:px-52 lg:px-[300px] xl:px-[500px]"> 
    <label className='text-white'>Name</label> <br/>
    <div className='flex'>
    <i class="fa fa-user icon text-black p-2 rounded-l-md bg-yellow-500"></i>
    <input 
    type='text' 
    name='Name' 
    placeholder='Enter your Name' 
    value={user.Name} 
    className='w-full p-1 rounded-r-md' 
    required
    onChange={data} 
    />
    </div>
    <br/>

    <label className='text-white'>Phone Number</label><br/>
    <div className='flex'>
    <i class="fa fa-phone icontext-black p-2 rounded-l-md bg-yellow-500"></i>
    <input 
    type='tel' 
    name='Number'
    value={user.Number} 
    placeholder="Enter Phone Number" 
  maxLength={10}
    className='w-full p-1 rounded-r-md' 
    required
    onChange={data} 
    />
    </div>
    <br/>

    <label className='text-white'>Message</label><br/>
    <div className='flex'>
    <i class="fa fa-edit icon text-black p-2 rounded-l-md bg-yellow-500"></i>
    <textarea 
    type='text' 
    name='Message'
    value={user.Message} 
    className='w-full p-1 rounded-r-md' 
    placeholder='Enter your Message' 
    required
    onChange={data} 
    >
    </textarea>
    </div>
    <button 
    onClick={getdata}  
    type="submit" 
    class="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-3 mt-4 rounded-md shadow-sm">
    SUBMIT
    </button>
      <ToastContainer
    position="top-center"
    autoClose={4000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
    transition= {Flip}
    />
  </motion.form>
</div>
<div className='mt-10 text-center lg:px-[200px]'>
<h1 class="text-xl text-yellow-500">GET IN TOUCH</h1>
<motion.ul
 initial={{x:-100, opacity:0}}
 whileInView={{x:0, opacity:1}}
 transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
  class="list-none justify-center md:flex md:space-x-5">
     
        <li className='font-semibold text-white text-lg'><i class="fa fa-phone text-yellow-500"> </i> +91-9808030923</li> 
        <a href= "mailto: support@govyapar.com"><li className='font-semibold text-white text-lg'><i class="fa fa-envelope text-yellow-500"> </i> support@govyapar.com</li> </a>     

<ul className='flex space-x-2 justify-center text-lg'>
        <a href='https://www.linkedin.com/company/govyapar/'><li><i class="fa fa-linkedin text-yellow-500 hover:font-bold"></i></li></a>
        {/* <li><i class="fa fa-twitter text-yellow-500 hover:font-bold"></i></li> */}
        <a href='https://www.instagram.com/govyapar/'><li><i class="fa fa-instagram text-yellow-500 hover:font-bold"></i></li></a>
        <a href = "https://www.facebook.com/govyapar"><li><i class="fa fa-facebook-f text-yellow-500 hover:font-bold"></i></li></a>
        <a href='https://www.youtube.com/@govyapar'><li><i class="fa fa-youtube text-yellow-500 hover:font-bold"></i></li></a>
</ul>
</motion.ul>
<div className='flex xl:px-36 px- pt-3 space-x-10 text-white cursor-pointer justify-center '>
    <div className='mx-auto '>
             <ul className='grid grid-cols-2 grid-rows-2 sm:grid-cols-4 sm:grid-rows-1 font-thin text-white mx-auto px-3 text-justify'>
            <div className='text-sm md:text-lg'>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>MENU</li>
            <Link to="/home"><li className=' hover:text-yellow-500'>Home</li></Link>
             <Link to="/about"> <li className=' hover:text-yellow-500'>About Us</li></Link>
            <Link to="/career"><li className=' hover:text-yellow-500'>Careers</li></Link>
            <Link to="/pricing"><li className=' hover:text-yellow-500'>Pricing</li></Link>
            <Link to="/articles"><li className=' hover:text-yellow-500'>Articles</li></Link>
            </div>

            <div className='text-sm md:text-lg'>
            <Link to="/affiliate"><li className=' hover:text-yellow-500'>Affiliate Program</li></Link>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>SERVICES</li>
            <Link to="/itrFiling"><li className=' hover:text-yellow-500'>ITR Filing</li></Link>
            <Link to="/gstFiling"><li className=' hover:text-yellow-500'>GST Filing</li></Link>
            <Link to="/notice"><li className=' hover:text-yellow-500'>Notices</li></Link>
            <Link to="/consultation"> <li className=' hover:text-yellow-500'>Consultation</li></Link>
            </div>

            <div className='text-sm md:text-lg'>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>SERVICES</li>
            <Link to="/tax-planner"> <li className=' hover:text-yellow-500'>Tax Planner</li></Link>
            <Link to="/ngo"><li className=' hover:text-yellow-500'>NGO</li></Link>
            <Link to="/trademark"><li className=' hover:text-yellow-500'>Trademark</li></Link>
            <Link to="/startupFunding"><li className=' hover:text-yellow-500'>Startup Funding</li></Link>
            </div>

            <div className='text-sm md:text-lg'>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>SERVICES</li>
            <Link to="/virtualCfo"> <li className=' hover:text-yellow-500'>Virtual CFO</li></Link>
            <Link to="/businessLoans"> <li className=' hover:text-yellow-500'>Business Loans</li></Link>
            <Link to="/"><li className=' hover:text-yellow-500'>ISO Certificate</li></Link>
            <Link to="/companyFormation"><li className=' hover:text-yellow-500'>Company Formation</li></Link>
            {/* <Link to="/tax-planner"><li className=' hover:text-yellow-500'> GET ECA FOR ITR FILING</li></Link> */}
            </div>
          </ul>
    </div>
    </div>
</div>
{/* <p className='border-dotted w-full  border-yellow-500 border-2'></p> */}

{/* ------------------------------------------------Footer Links------------------------------------------------- */}
<hr className='text-white w-full my-3'/>

{/* ---------------------------------------------Foooter------------------------------------------- */}
<footer className='text-white text-sm text-center mb-0 mt-2'>
  ©Copyright 2024. All Rights Reserved. Govyapar
</footer>

</div>  
    </>
  )
}

export default VirtualCfo
