import "./App.css";
import React from 'react';
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs.js";
import Service from "./components/Service";
import Affiliate from "./components/Affiliate/Affiliate";
import SignIn from "./components/Affiliate/SignIn";
import SignUp from "./components/Affiliate/SignUp";
// import UserLogin from "./components/Affiliate/UserLogin";
import Notice from "./components/Notice";
import Contact from "./components/Contact";
import Consultation from "./components/Consultation";
import TalkCA from "./components/TalkCA.js";
import TalkCS from "./components/TalkCS.js";
import TalkLawyer from "./components/TalkLawyer.js";
import TalkTrademark from "./components/TalkTrademark.js";
import CompanyFormation from "./components/CompanyFormation";
import DirectorDetails from "./components/DirectorDetails";
import ShareholderDetails from "./components/ShareholderDetails";
import Payment from "./components/Payment";
import Pricing from "./components/Pricing";
import TaxPlanner from "./components/TaxPlanner";
import Continue from "./components/Continue";
import ItrFiling from "./components/ItrFiling";
import GstFiling from "./components/GstFiling";
import NGO from "./components/NGO";
import Trademark from "./components/Trademark";
import StartupFunding from "./components/StartupFunding";
import VirtualCfo from "./components/VirtualCfo";
import Career from "./components/Career";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // Navigate,
  useLocation
} from "react-router-dom";
import { useEffect, useState } from "react";
// import { auth } from "./firebase.config";
import ItrSalaryPlan from "./components/RazorpayButtons/ItrSalaryPlan";
import GstRegistration from "./components/RazorpayButtons/GstRegistration";
import ItrFileUpload from "./components/ItrFileUpload";
import GstFileUpload from "./components/GstFileUpload";
import BusinessLoans from "./components/BusinessLoans";
import TestMail from "./components/TestMail";
import ScheduleCall from "./components/ScheduleCall";
import Modal from "./components/Modal";
import LatestArticles from "./components/Articles/LatestArticles.js";
import Page1 from "./components/Articles/Page1.js";
import Page2 from "./components/Articles/Page2.js";
import Page3 from "./components/Articles/Page3.js";
import Page4 from "./components/Articles/Page4.js";
import Dashboard from "./components/Dashboard/Dashboard.js";
import PrivateRoute from "./components/Affiliate/PrivateRoute.js";

  function App(){
    const [userName, setUserName] = useState("");

    useEffect(() => {
      // Fetch user data from the backend or check local storage
      const token = localStorage.getItem('token');
      if (token) {
        // Assume user data can be fetched with a token, or extract from local storage
        setUserName("User Name from Backend"); // Replace with actual logic
      }
    }, []);

  return (
    <>

    <Router basename = "/">
    <Layout>
    <Routes>
      {/* --------------------------------------------Navbar------------------------------------ */}
      <Route index element={<Home />} />
      <Route path="/service" element={<Service />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/consultation" element={<Consultation />} />
      <Route path="/talkCA" element={<TalkCA/>} />
      <Route path="/talkCS" element={<TalkCS/>} />
      <Route path="/talkLawyer" element={<TalkLawyer/>} />
      <Route path="/talkTrademark" element={<TalkTrademark/>} />

              {/* -------company formation------ */}
      <Route path="/companyFormation" element={<CompanyFormation />} />
      <Route path="/directorDetails" element={<DirectorDetails />} />
      <Route path="/shareholderDetails" element={<ShareholderDetails />} />
      <Route path="/payment" element={<Payment/>} />

              {/* --------services----- */}
      <Route path="/tax-planner" element={<TaxPlanner />} />
      <Route path="/notice" element={<Notice />} />
      <Route path="/itrFiling" element={<ItrFiling />} />
      <Route path="/gstFiling" element={<GstFiling />} />
      <Route path="/ngo" element={<NGO />} /> 
      <Route path="/trademark" element={<Trademark />} /> 
      <Route path="/startupFunding" element={<StartupFunding />} /> 
      <Route path="/virtualCfo" element={<VirtualCfo />} /> 
      <Route path="/businessLoans" element={<BusinessLoans/>} />
      <Route path="/pricing" element={<Pricing />} />

            {/* --------affiliate--------- */}
      <Route path="/affiliate" element={<Affiliate Program />} />
      <Route path="/signIn" element={<SignIn />} />
      <Route path="/signUp" element={<SignUp />} />

      <Route path="/contact" element={<Contact />} />
    {/* --------------------------------------------Navbar End------------------------------------ */}

      <Route path="/career" element={<Career />} />
      <Route path="/continue" element={<Continue />} /> 
      <Route path="/testMail" element={<TestMail />} /> 

      {/* ---------------------------------Articles------------------------------------ */}
      <Route path="/articles" element={<LatestArticles />} /> 
      <Route path="/page1" element={<Page1 />} />
      <Route path="/page2" element={<Page2 />} /> 
      <Route path="/page3" element={<Page3/>} /> 
      <Route path="/page4" element={<Page4 />} />  

      {/* ------------------------------Plans Files Upload--------------------------------------- */}
      <Route path="/itrfileUpload" element={<ItrFileUpload />} /> 
      <Route path="/gstFileUpload" element={<GstFileUpload/>} /> 

      {/* -----------------------RazorpayButtons----------------- */}
      <Route path="/itrSalaryPlan" element={<ItrSalaryPlan />} /> 
      <Route path="/gstRegistration" element={<GstRegistration />} /> 

      {/* ------------------------modal---------------------------- */}
      <Route path = "/modal" element={<Modal/>} />
      <Route path = "/scheduleCall" element= {<ScheduleCall/>} />
      <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
       </Routes>
       </Layout>
       </Router> 
       
    </>
  );
};

function Layout({ children }) {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/dashboard" && <Navbar />}
      {children}
    </>
  );
}

export default App
