import React from 'react';
import { motion} from "framer-motion";

function Page4() {
  window.scrollTo(0,0);
  return (
    <>
 {/* ----------------------------------------------Heading------------------------ */}
      
      {/* -------------------------- ‘Rapido’ is an E-commerce Operator & liable to GST: Karnataka AAR---------------------------- */}
          <section className='container max-w-6xl mx-auto pt-32'>
          <div className="flex flex-col space-y-2 text-yellow-500 text-center mb-10">
          <h1 className="text-xl font-bold md:text-3xl">
            ‘Rapido’ is an E-commerce Operator & liable to GST: Karnataka AAR
          </h1>
          <div className="flex items-center space-x-2 text-xs md:text-sm text-gray-600 mx-auto">
            <i className="material-icons">calendar_today</i>
            <span>05 Aug 2024</span>
            <i className="material-icons">visibility</i>
            <span>624 Views</span>
            <i className="material-icons">comment</i>
            <span>0 comment</span>
          </div>
          <div className="text-sm text-gray-500">
            <span>Goods and Services Tax</span> | <span>Articles</span>
          </div>
        </div>
          <img src="Why Do.png" className='md:float-left md:w-[60%] xl:w-[50%]' alt=''/>
          <h3 className='text-md md:text-xl font-semibold text-yellow-500'>Rapido” is an E-com and liable for GST in terms of Sec 9(5) of CGST Act 2017: Karnataka AAR</h3>
         
          <p className='text-lg text-justify '>
          In recent case of Roppen Transportation Services Private Limited, Karnataka AAR concluded that, Service of “Rapido” is E-com service and E-com is liable under GST u/s 9(5), for the transportation of passenger booked through App.
          <br/>
           Fact of the case:- Applicant is registered private limited company, also registered under the GST Act and are engaged in the business of providing technology based services to their end users for booking two-wheelers and three-wheeler passenger transport services offered by third party drivers by means of the company website and its mobile app platform “Rapido”.
          <br/>
           The applicant proposes to introduce computer/mobile application services to independent four-wheeler cab service providers on subscription basis, as a discovery platform to enable them to connect with potential end-users (also referred to as passengers, who require the services of such third party drivers on its platform. The third party drivers/subscribers) shall enter into a standard agreement to create a business user account, on furnishing certain documents, which shall be limited to providing of license or permission to use the said app. The applicant also proposes to introduce pay per use ride monitoring fee, which is an optional service provided to the passengers, to ensure the passenger’s safety during the ride that the passenger avails from the four-wheeler cab service providers.
          </p>
          <br/>
          <ol className='list-decimal list-inside text-lg space-y-3 text-justify'>
          <h1 className="text-xl text-yellow-500">Question posed to AAR. </h1>
          <li>Whether the Applicant satisfies the definition of an e-commerce operator and the nature of supply as conceptualized in Section 9 (5) of CGST Act, 2017 r/w notification No. 17/2017 dated 28.06.2017?</li>

          <li>Whether the supply by the independent four-wheeler cab service provider (person who has subscribed to Rapido’ app) to his passengers (who do not pay any subscription feel on the app platform amounts to supply by the Applicant?</li>

          <li>Whether the Applicant is liable to pay GST on the supply of services provided by the independent four-wheeler cab service provider (person who has subscribed to applicant’s ‘Rapido’ app) to his passengers on the Applicant’s app platform?</li>

          <li>What is the rate at which GST shall be collected on the ride monitoring fee and the SAC code that shall be applicable?</li>

          <li>Whether the Applicant is liable to pay GST on the supply of services provided by the independent three/two-wheeler cab service provider (person who has subscribed to applicant’s ‘Rapido* app) to his passengers on the Applicant’s app platform?</li>
          </ol>
          <br/>
          <br/>

          <p className='text-lg text-justify '>
          <h1 className="text-xl text-yellow-500">AAR Finding and conclusion:-</h1>
          Electronic Commerce Operator (ECO) means any person who owns, operates or manages digital or electronic facility or platform for electronic commerce i.e. for the supply of goods or services or both, including digital products over digital or electronic network. In the instant case the applicant owns digital platform (‘Rapido’ APP). for the supply of services. Thus the applicant squarely fits into the definition and qualifies to be an Electronic Commerce Operator.
          <br/>
          Vide Notification 17/2017-Central Tax (Rate) dated 28.06.2017. issued under Section 9 (5) of the CGST Act, 2017, Government has notified that tax on intra-state supplies for ‘(i) services by way of transportation of passengers by a radio-taxi, motorcab, maxicab and motor cycle;’ shall be paid by the electronic commerce operator. Further explanation (b) to the said notification specifies that “maxicab”, “motorcab”, and “motor cycle” shall have the same meaning as assigned to them respectively in clauses (22), (25) and (26) of Section 2 of the Motor Vehicles Act, 1988.
          <br/>
          The services of transportation of passengers are provided by a four-wheel car. which is a motor vehicle adapted to carry maximum four passengers excluding driver and thereby it can carry not more than six passengers excluding the driver and hence qualifies to be a “motorcab”. Titus the first two conditions viz., (a) and (b) are satisfied in the instant case, in as much as the category of services of Intra-state supplies are notified by the Government covering services by way of transportation of passengers by motor cab.
          <br/>
          The passenger pays the consideration to the driver directly. It is pertinent to mention here that neither the definitions of electronic commerce under Section 2 (44) or electronic commerce operator under Section 2 (45) nor the charging Section 9 (5) of the CGST Act 2017 stipulates that the e-commerce operator has to collect the consideration.
          <br/>
          The applicant also proposes to introduce pay-per-use ride monitoring charge, an optional service provided to the passengers, to ensure the passenger’s safety before the ride, during the ride and after the ride. The said service involves providing rider confidentiality of customer information, notifying route deviation, long waits, ride sharing, SOS, customer support on call for ensuring safety of passenger. The said optional service is intended to be provided to the passenger directly by the applicant for which a separate standardized consideration is charged based on the distance and time of the ride The said service is covered SAC 9985 (specifically 998522 Security consulting services) attracting 18% GST( CGST 9% and SGST 9%) in terms of Sl.No. 23(iii) of Notification 11/2017-Central Tax (Rate) dtd 28.07.2017 (i.e. Other support services).
          <br/>
          <br/>
      {/* -----------------------------------------Conclusion------------------ */}
     <h1 className="text-xl text-yellow-500">Conclusion:- </h1>
      {/* -- Question 1 --*/}
      <div class="mb-6">
      <p class="font-bold text-gray-800 mb-2">
      Q.1 Whether the Applicant satisfies the definition of an e-commerce operator and the nature of supply as conceptualized in Section 9 (5) of CGST Act, 2017 r/w notification No. 17/2017 dated 28.06.2017?
      </p>
      <p class="font-bold text-gray-800">Ruling:</p>
      <p class="text-gray-800">
        The Applicant satisfies the definition of an e-commerce operator and the nature of supply as conceptualized in Section 9(5) of CGST Act, 2017 r/w notification No. 17/2017 dated 28.06.2017.
        </p>
      </div>

       {/* -- Question 2 --*/}
      <div>
        <p class="font-bold text-gray-800 mb-2">
          Q.2 Whether the supply by the independent four-wheeler cab service provider (person who has subscribed to ‘Rapido’ app) to his passengers (who do not pay any subscription fee) on the app platform amounts to supply by the Applicant?
        </p>
        <p class="font-bold text-gray-800">Ruling:</p>
        <p class="text-gray-800">
          The supply by the independent four-wheeler cab service provider (person who has subscribed to ‘Rapido’ app) to his passengers (who do not pay any subscription fee) on the App platform amounts to supply by the Applicant by virtue of Section 9(5) of CGST Act. 2017.
        </p>
      </div>
          
           {/* -- Question 3 --*/}
      <div>
        <p class="font-bold text-gray-800 mb-2">
          Q.3 Whether the Applicant is liable to pay GST on the supply of services provided by the independent four-wheeler cab service provider (person who has subscribed to applicant’s ‘Rapido’ app) to his passengers on the Applicant’s app platform?
        </p>
        <p class="font-bold text-gray-800">Ruling:</p>
        <p class="text-gray-800">
        The Applicant is liable to pay GST on the supply of services provided by the independent four-wheeler cab service provider (person who has subscribed to applicant’s ‘Rapido’ app) to his passengers on the Applicant’s app platform, being an e-commerce operator, in terms of Section 9 (5) of the CGST Act 2017 read with relevant CGST Rules 2017.
        </p>
      </div>
          
           {/* -- Question 4 --*/}
      <div>
        <p class="font-bold text-gray-800 mb-2">
          Q.4 What is the rate at which GST shall be collected on the ride monitoring fee and the SAC code that shall be applicable?
        </p>
        <p class="font-bold text-gray-800">Ruling:</p>
        <p class="text-gray-800">
        18% GST (9% CGST and 9% SGST) is applicable on the ride monitoring fee and SAC code 9985 is applicable for the said service.
        </p>
      </div>

         
          {/* -- Question 5 --*/}
      <div>
        <p class="font-bold text-gray-800 mb-2">
          Q.5 Whether the Applicant is liable to pay GST on the supply of services provided by the independent three/two-wheeler cab service provider (person who has subscribed to applicant’s ‘Rapido* app) to his passengers on the Applicant’s app platform?
        </p>
        <p class="font-bold text-gray-800">Ruling:</p>
        <p class="text-gray-800">
        The Applicant is liable to pay GST on the supply of services provided by the independent three/two-wheeler cab service provider (person who has subscribed to applicant’s ‘Rapido’ app) to his passengers on the Applicant’s app platform.
        </p>
      </div>
        </p>
        </section>

    {/* ---------------------------Leave a Comment------------------------------------- */}
      <div class="max-w-xl mx-auto bg-white p-6 rounded shadow-lg my-20">
  
    <h2 class="text-xl font-bold mb-4">Leave a Comment</h2>
    
    <div class="bg-gray-500 text-yellow-500 p-3 rounded mb-6">
      Your email address will not be published. Required fields are marked *
    </div>

    {/* ------------------------ Form ---------------------- */}
    <form>
      <div class="flex items-center mb-4">
        <span class="material-icons text-gray-500 mr-2">person</span>
        <input type="text" name="name" placeholder="Name *" class="w-full p-3 border rounded"/>
      </div>
      
      <div class="flex items-center mb-4">
        <span class="material-icons text-gray-500 mr-2">email</span>
        <input type="email" name="email" placeholder="Email *" class="w-full p-3 border rounded"/>
      </div>
      
      <div class="flex items-center mb-4">
        <span class="material-icons text-gray-500 mr-2">link</span>
        <input type="url" name="website" placeholder="Website" class="w-full p-3 border rounded"/>
      </div>

      <div class="mb-4">
        <textarea name="comment" placeholder="Comment" class="w-full p-3 border rounded  h-24"></textarea>
      </div>
      
      {/* <!-- Submit Button --> */}
      <div class="text-right">
        <button type="submit" class="bg-yellow-500 text-white py-2 px-4 rounded hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-600">
          Post Comment
        </button>
      </div>
    </form>
  </div>

    </>
  );
};

export default Page4;
