import React, { useEffect } from 'react';

const RazorpayButtonNavbar = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/payment-button.js';
    script.setAttribute('data-payment_button_id', 'pl_OVetdahdHuLW1S');
    script.async = true;
    document.getElementById('razorpay-button-container').appendChild(script);
  }, []);

  return (
    <>
    <div><form id="razorpay-button-container">
   
      {/* The script will dynamically add the payment button here */}
    </form>
    </div> 
    </>

  );
};

export default RazorpayButtonNavbar;