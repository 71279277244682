// Sidebar.js
import React, { useState } from 'react';
import { HomeOutlined, UserOutlined, SettingFilled, LogoutOutlined, BellOutlined, SearchOutlined } from '@ant-design/icons';
import { Avatar, Input } from 'antd';
import { Link } from 'react-router-dom';

const Sidebar = ({ user }) => {
  const [isOpen, setIsOpen] = useState({
    overview: true,
    profile: false,
    settings: false,
  });

  const toggleMenu = (section) => {
    setIsOpen((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="flex flex-col w-64 bg-gray-900 text-white h-screen p-6">
      
        <div>
          <h2 className="text-xl font-semibold text-gray-100">Welcome, {user.name}!</h2>
          <p className="text-gray-400">{user.email}</p>
        </div>

      {/* Search Bar */}
      <div className="mb-6">
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search..."
          className="bg-white-800 text-gray-900 border-none"
        />
      </div>

      {/* Navigation Links */}
      <nav className="flex-1">
        <ul className="space-y-4">
          <li>
            <Link
              to="/dashboard/overview"
              className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-700 transition-colors"
              onClick={() => toggleMenu('overview')}
            >
              <HomeOutlined className="text-xl" />
              <span className="text-lg font-medium">Overview</span>
            </Link>
            {isOpen.overview && (
              <ul className="ml-4 space-y-2">
                <li>
                  <Link
                    to="/dashboard/overview/analytics"
                    className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-600 transition-colors"
                  >
                    <span className="text-md">Analytics</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/dashboard/overview/reports"
                    className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-600 transition-colors"
                  >
                    <span className="text-md">Reports</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link
              to="/dashboard/profile"
              className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-700 transition-colors"
              onClick={() => toggleMenu('profile')}
            >
              <UserOutlined className="text-xl" />
              <span className="text-lg font-medium">Profile</span>
            </Link>
            {isOpen.profile && (
              <ul className="ml-4 space-y-2">
                <li>
                  <Link
                    to="/dashboard/profile/edit"
                    className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-600 transition-colors"
                  >
                    <span className="text-md">Edit Profile</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/dashboard/profile/security"
                    className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-600 transition-colors"
                  >
                    <span className="text-md">Security Settings</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link
              to="/dashboard/settings"
              className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-700 transition-colors"
              onClick={() => toggleMenu('settings')}
            >
              <SettingFilled className="text-xl" />
              <span className="text-lg font-medium">Settings</span>
            </Link>
            {isOpen.settings && (
              <ul className="ml-4 space-y-2">
                <li>
                  <Link
                    to="/dashboard/settings/preferences"
                    className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-600 transition-colors"
                  >
                    <span className="text-md">Preferences</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/dashboard/settings/account"
                    className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-600 transition-colors"
                  >
                    <span className="text-md">Account Settings</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <button
              className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-700 transition-colors w-full text-left"
              onClick={() => console.log('Logout')}
            >
              <LogoutOutlined className="text-xl" />
              <span className="text-lg font-medium">Logout</span>
            </button>
          </li>
        </ul>
      </nav>

      {/* Notifications Section */}
      <div className="mt-6 bg-gray-800 p-4 rounded-lg shadow-md">
        <div className="flex items-center space-x-2 mb-4">
          <BellOutlined className="text-xl" />
          <h3 className="text-lg font-semibold">Notifications</h3>
        </div>
        <p className="text-gray-400">No new notifications</p>
      </div>
    </div>
  );
};

export default Sidebar;
