import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'; // Import the Sidebar component
import { Avatar, Button, Card } from 'antd';
import { UserOutlined, HomeOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SERVER_ENDPOINT } from '../constants/api_endpoint';

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    name: '',
    email: '',
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
          setUser({
            name: storedUser.name,
            email: storedUser.email,
          });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false); 
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    setLoading(true); 
    try {
      await axios.post(`${SERVER_ENDPOINT}/api/user/logout`, {}, { withCredentials: true });

      toast.success('Logged out successfully!');

      navigate('/signIn');
    } catch (error) {
      // Show error toast
      toast.error('Logout failed. Please try again.');
      console.error('Logout Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
          <div className="dots-loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      ) : (
        <div className="flex h-screen">
          <Sidebar user={user} /> 
          <div className="flex-1 p-6 bg-gray-100">
            <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-6 mt-8">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <Avatar size={64} icon={<UserOutlined />} />
                  <div>
                    <h1 className="text-2xl font-bold text-gray-900">Welcome, {user.name}!</h1>
                    <p className="text-gray-600">{user.email}</p>
                  </div>
                </div>
                <Button type="primary" danger icon={<LogoutOutlined />} onClick={handleLogout}>
                  Logout
                </Button>
              </div>
              <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                <Card title="Overview" extra={<HomeOutlined />} className="shadow-md">
                  <p className="text-gray-700">
                    Get a quick overview of your dashboard activities.
                  </p>
                  <Button type="primary">View Overview</Button>
                </Card>

                <Card title="Settings" extra={<SettingOutlined />} className="shadow-md">
                  <p className="text-gray-700">
                    Manage your account settings and preferences.
                  </p>
                  <Button type="primary">Update Settings</Button>
                </Card>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
