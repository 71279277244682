import React, { useState } from 'react';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Modal() {
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState({
    Name: '', Number: '', Message: ''
  });

  let name, value;
  const data = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const getdata = async (e) => {
    e.preventDefault();
    if (!user.Name || !user.Number) {
      toast.error('Please fill all fields.', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
      return false;
    }

    const { Name, Number } = user;
    const options = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        Name, Number
      })
    };

    const res = await fetch(
      'https://govyapar-contact-default-rtdb.firebaseio.com/UserData.json',
      options
    );

    if (res) {
      toast.success('Thank you for reaching out! Our team will contact you shortly.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
      setUser({ Name: '', Number: '', Message: '' }); // Clear form
      return true;
    } else {
      toast.error('Error occurred while submitting the form.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
      return false;
    }
  };

  const handleSubmit = async (e) => {
    const success = await getdata(e);
    if (success) {
      setShowModal(false);
    }
  };

  return (
    <>
      <button
        className="bg-yellow-500 text-white uppercase font-bold py-2 px-4 rounded-md shadow-black-md text-sm hover:scale-105 duration-300 ease-in mt-4"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Choose Plan
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="text-center bg-black text-yellow-500 p-3 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Get in Touch
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    {/* <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span> */}
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <form method='POST' className="px-10">
                    <label className='text-white'>Name</label> <br />
                    <div className='flex'>
                      <i className="fa fa-user icon text-black p-2 rounded-l-md"></i>
                      <input
                        type='text'
                        name='Name'
                        placeholder='Enter your Name'
                        value={user.Name}
                        className='w-full p-1 rounded-r-md border-1 border-black'
                        required
                        onChange={data}
                      />
                    </div>
                    <br />

                    <label className='text-white'>Phone Number</label><br />
                    <div className='flex'>
                      <i className="fa fa-phone icontext-black p-2 rounded-l-md"></i>
                      <input
                        type='tel'
                        name='Number'
                        value={user.Number}
                        placeholder="Enter Phone Number"
                        maxLength={10}
                        className='w-full p-1 rounded-r-md border-1 border-black'
                        required
                        onChange={data}
                      />
                    </div>
                    <br />

                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="bg-transparent border-1 border-black hover:bg-yellow-600 text-black font-bold py-2 px-3 mt-2 rounded-full shadow-sm">
                      SUBMIT
                    </button>
                  </form>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent border-1 border-red-700 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="colored"
        transition={Flip}
      />
    </>
  );
}
