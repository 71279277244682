import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SERVER_ENDPOINT } from '../constants/api_endpoint';
// eslint-disable-next-line
import { toast, ToastContainer } from 'react-toastify';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

function SignIn() {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [values, setValues] = useState({
    emailOrMobile: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmission = async (e) => {
    e.preventDefault();

    // Validate input fields
    if (!values.emailOrMobile || !values.password) {
      toast.error("Please fill in all fields", { autoClose: 3000 });
      return;
    }

    setLoading(true);

    
    try {
      // Send login request
      const response = await axios.post(`${SERVER_ENDPOINT}/api/user/login`, values, {
        withCredentials: true,
      });

      if (response.data.success) {
        const { token, user } = response.data; // Extract token and user data
        if (token) {
          localStorage.setItem('token', token); // Store token in localStorage
          localStorage.setItem('user', JSON.stringify(user)); // Store user data
          toast.success(response.data.message || "Login Successful", { autoClose: 3000 });
          navigate("/dashboard");
        } else {
          toast.error("No token received. Login failed.", { autoClose: 3000 });
        }
      } else {
        toast.error(response.data.message || "An error occurred during login", { autoClose: 3000 });
      }
    } catch (error) {
      // Handle different error scenarios
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || "An error occurred during login", { autoClose: 3000 });
      } else {
        toast.error("An unexpected error occurred", { autoClose: 3000 });
      }
      console.error("Login Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='text-center items-center md:mt-40 mt-20 w-full mx-0'>

      <form method='POST' onSubmit={handleSubmission} className="flex flex-col space-y-2 h-screen md:h-auto max-w-xl md:my-10 pd:my-20 mx-auto px-4 pt-5 md:py-8 rounded-lg shadow-lg bg-white">
        <div className='text-center'>
          <img src='govyapar logo.png' className="md:px-56 px-32" alt="Govyapar Logo" />
          <h1 className="text-xl font-bold">Login to your Affiliate Dashboard</h1>
        </div>

        <label className='text-black text-left'>Email or Mobile</label>
        <div className='flex'>
          <i className="fa fa-envelope icontext-black p-2 rounded-l-md bg-yellow-500 border-t-2 border-l-2 border-b-2 border-black"></i>
          <input
            type='text'
            name='emailOrMobile'
            placeholder='Enter your Email or Mobile'
            className='w-full p-1 rounded-r-md border-2 border-black'
            required
            value={values.emailOrMobile}
            onChange={(event) =>
              setValues((prev) => ({ ...prev, emailOrMobile: event.target.value }))
            }
          />
        </div>

        <br />

        <label className='text-black text-left'>Password</label>
        <div className='flex relative'>
          <i className="fas fa-key text-black p-2 rounded-l-md border-t-2 border-l-2 border-b-2 border-black bg-yellow-500"></i>
          <input
            name='password'
            placeholder='Enter your Password'
            type={passwordVisible ? 'text' : 'password'}
            className='w-full p-1 rounded-r-md border-2 border-black'
            required
            value={values.password}
            onChange={(event) =>
              setValues((prev) => ({ ...prev, password: event.target.value }))
            }
          />
          <span
            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {passwordVisible ? <EyeTwoTone />
              : <span className="text-yellow-500">
                <EyeInvisibleOutlined />
              </span>}
          </span>
        </div>

        {loading ? (
          <div className="flex justify-center items-center py-4">
            <div className="dots-loader">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        ) : (
          <button
            type="submit"
            disabled={loading}
            className="bg-yellow-500 hover:bg-yellow-600 md:mx-48 mx-28 w-[30%] text-black font-bold py-2 px-3 mt-4 rounded-md shadow-sm">
            SUBMIT
          </button>
        )}
        <h1 className="text-md text-black">Don't have an account? <Link to="/signUp"><span className='text-blue-500'>Sign Up</span></Link></h1>
        <h1 className="text-md text-black">Forgot password? Write to us at <a href='mailto:govyaparemail@govyapar.com' className='text-blue-500'>support@govyapar.com</a></h1>
      </form>
    </div>
  );
}

export default SignIn;
