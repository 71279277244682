import React, { useRef, useState } from 'react'
import {motion} from "framer-motion";
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact(){

  // --------------------------------------------subscribe to our newsletter--------------------------------
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_2shjaqt', 
        'template_yb1ikfz', 
        form.current, {
        publicKey: 'KapdrjZ67SvjEm1Jl',
      })
      .then(
        () => {
          toast.success('Subscribed', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
            });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  // --------------------------------------------contact---------------------------
  const[user, setUser] = useState(
    {
        Name: '', Number:'', Message:''
    }
  )
  // const [errorMsg, setErrorMsg] = useState("");
  let name, value
  const data = (e) =>
  {
    console.log(user)
    name = e.target.name;
    value = e.target.value;
    setUser({...user, [name]:value});
  }
  const getdata = async (e) => 
  {
  if (!user.Name || !user.Number || !user.Message) {
    // setErrorMsg("Fill all fields");
    return;
  }
    // setErrorMsg("");
    
    const{Name, Number, Message} = user;
    e.preventDefault();
    const options = {
        method: 'POST',
        headers: {
            'Content-type' : 'application/json'
        },
        body: JSON.stringify({
            Name, Number, Message
        })
    }
    const res = await fetch(
        'https://govyapar-contact-default-rtdb.firebaseio.com/UserData.json',
        options
        )
        if(res)
        {
          toast.success('Message sent successfully', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
            });
        }
        else
        {
            alert("Error Ocurred")
        }
      }

      window.scrollTo(0,0);
  return(
    <>
        {/* -----------------------------------------------Subscribe to our newsletter------------------------------------------------ */}
        <div class="container bg-black mt-20 pt-10 max-w-full scroll-smooth">
  <div class="md:flex justify-between items-center lg:px-28 py-10 ">
    <motion.div
     initial={{x:-100, opacity:0}}
     whileInView={{x:0, opacity:1}}
     transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
     >
      <h1 class="md:text-4xl text-2xl text-yellow-500 font-bold  sm:text-center md:text-left leading-tight">Subscribe to us!</h1>
      <p class="text-md text-white my-2">Subscribe to our newsletter for exclusive updates and offers.</p>
    </motion.div>

    <form ref={form} onSubmit={sendEmail}>
      <motion.div  initial={{x:-100, opacity:0}}
      whileInView={{x:0, opacity:1}}
      transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
      class="flex">
        <input 
        type="email" 
        placeholder="Enter your email" 
        name="user_email" 
        required 
        class="rounded-md px-4 py-2 w-full mr-2 focus:outline-none focus:ring-1 focus:ring-yellow-500" />
        <input 
        type="submit" 
        value="Subscribe" 
        class="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded-md shadow-sm"/>

      </motion.div>
    </form>
  </div>

    {/* ----------------------------------------------------------Contact------------------------------------------------------- */}
<div>
  <h1 className="text-2xl text-yellow-500 font-bold text-center">Contact</h1>
  <motion.form method='POST' 
   initial={{y:-100, opacity:0}}
   whileInView={{y:0, opacity:1}}
   transition={{delay:0.10, y:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
    className="text-xl space-y-1 rounded-md mx-3 md:pt-10 pt-2 text-center sm:px-32 md:px-52 lg:px-[300px] xl:px-[500px]"> 
    <label className='text-white'>Name</label> <br/>
    <div className='flex'>
    <i class="fa fa-user icon text-black p-2 rounded-l-md bg-yellow-500"></i>
    <input 
    type='text' 
    name='Name' 
    placeholder='Enter your Name' 
    value={user.Name} 
    className='w-full p-1 rounded-r-md' 
    required
    onChange={data} 
    />
    </div>
    <br/>

    <label className='text-white'>Phone Number</label><br/>
    <div className='flex'>
    <i class="fa fa-phone icontext-black p-2 rounded-l-md bg-yellow-500"></i>
    <input 
    type='tel' 
    name='Number'
    value={user.Number} 
    placeholder="Enter Phone Number" 
  maxLength={10}
    className='w-full p-1 rounded-r-md' 
    required
    onChange={data} 
    />
    </div>
    <br/>

    <label className='text-white'>Message</label><br/>
    <div className='flex'>
    <i class="fa fa-edit icon text-black p-2 rounded-l-md bg-yellow-500"></i>
    <textarea 
    type='text' 
    name='Message'
    value={user.Message} 
    className='w-full p-1 rounded-r-md' 
    placeholder='Enter your Message' 
    required
    onChange={data} 
    >
    </textarea>
    </div>
    <button 
    onClick={getdata}  
    type="submit" 
    class="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-3 mt-4 rounded-md shadow-sm">
    SUBMIT
    </button>
      <ToastContainer
    position="top-center"
    autoClose={4000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
    transition= {Flip}
    />
  </motion.form>
</div>
<div className='mt-10 text-center lg:px-[200px]'>
<h1 class="text-xl text-yellow-500">GET IN TOUCH</h1>
<motion.ul
 initial={{x:-100, opacity:0}}
 whileInView={{x:0, opacity:1}}
 transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
  class="list-none justify-center md:flex md:space-x-5">
     
        <li className='font-semibold text-white text-lg'><i class="fa fa-phone text-yellow-500"> </i> +91-9808030923</li> 
        <a href= "mailto: support@govyapar.com"><li className='font-semibold text-white text-lg'><i class="fa fa-envelope text-yellow-500"> </i> support@govyapar.com</li> </a>     

<ul className='flex space-x-2 justify-center text-lg'>
        <a href='https://www.linkedin.com/company/govyapar/'><li><i class="fa fa-linkedin text-yellow-500 hover:font-bold"></i></li></a>
        {/* <li><i class="fa fa-twitter text-yellow-500 hover:font-bold"></i></li> */}
        <a href='https://www.instagram.com/govyapar/'><li><i class="fa fa-instagram text-yellow-500 hover:font-bold"></i></li></a>
        <a href = "https://www.facebook.com/govyapar"><li><i class="fa fa-facebook-f text-yellow-500 hover:font-bold"></i></li></a>
        <a href='https://www.youtube.com/@govyapar'><li><i class="fa fa-youtube text-yellow-500 hover:font-bold"></i></li></a>
</ul>
</motion.ul>
<div className='flex xl:px-36 px- pt-3 space-x-10 text-white cursor-pointer justify-center '>
    <div className='mx-auto '>
             <ul className='grid grid-cols-2 grid-rows-2 sm:grid-cols-4 sm:grid-rows-1 font-thin text-white mx-auto px-3 text-justify'>
            <div className='text-sm md:text-lg'>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>MENU</li>
            <Link to="/home"><li className=' hover:text-yellow-500'>Home</li></Link>
             <Link to="/about"> <li className=' hover:text-yellow-500'>About Us</li></Link>
            <Link to="/career"><li className=' hover:text-yellow-500'>Careers</li></Link>
            <Link to="/pricing"><li className=' hover:text-yellow-500'>Pricing</li></Link>
            <Link to="/articles"><li className=' hover:text-yellow-500'>Articles</li></Link>
            </div>

            <div className='text-sm md:text-lg'>
            <Link to="/affiliate"><li className=' hover:text-yellow-500'>Affiliate Program</li></Link>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>SERVICES</li>
            <Link to="/itrFiling"><li className=' hover:text-yellow-500'>ITR Filing</li></Link>
            <Link to="/gstFiling"><li className=' hover:text-yellow-500'>GST Filing</li></Link>
            <Link to="/notice"><li className=' hover:text-yellow-500'>Notices</li></Link>
            <Link to="/consultation"> <li className=' hover:text-yellow-500'>Consultation</li></Link>
            </div>

            <div className='text-sm md:text-lg'>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>SERVICES</li>
            <Link to="/tax-planner"> <li className=' hover:text-yellow-500'>Tax Planner</li></Link>
            <Link to="/ngo"><li className=' hover:text-yellow-500'>NGO</li></Link>
            <Link to="/trademark"><li className=' hover:text-yellow-500'>Trademark</li></Link>
            <Link to="/startupFunding"><li className=' hover:text-yellow-500'>Startup Funding</li></Link>
            </div>

            <div className='text-sm md:text-lg'>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>SERVICES</li>
            <Link to="/virtualCfo"> <li className=' hover:text-yellow-500'>Virtual CFO</li></Link>
            <Link to="/businessLoans"> <li className=' hover:text-yellow-500'>Business Loans</li></Link>
            <Link to="/"><li className=' hover:text-yellow-500'>ISO Certificate</li></Link>
            <Link to="/companyFormation"><li className=' hover:text-yellow-500'>Company Formation</li></Link>
            {/* <Link to="/tax-planner"><li className=' hover:text-yellow-500'> GET ECA FOR ITR FILING</li></Link> */}
            </div>
          </ul>
    </div>
    </div>
</div>
{/* <p className='border-dotted w-full  border-yellow-500 border-2'></p> */}

{/* ------------------------------------------------Footer Links------------------------------------------------- */}
<hr className='text-white w-full my-3'/>

{/* ---------------------------------------------Foooter------------------------------------------- */}
<footer className='text-white text-sm text-center mb-0 mt-2'>
  ©Copyright 2024. All Rights Reserved. Govyapar
</footer>

</div>    
    </>
  )
}
export default Contact

