import React, { useRef, useState } from 'react'
import {motion} from "framer-motion";
import {  Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { ToastContainer, Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaStar, FaDownload, FaUsers, FaCommentDots, FaHandshake } from 'react-icons/fa';

function AboutUs() {
    const[open, setOpen] = useState(true);

  // --------------------------------------------subscribe to our newsletter--------------------------------
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_2shjaqt', 
        'template_yb1ikfz', 
        form.current, {
        publicKey: 'KapdrjZ67SvjEm1Jl',
      })
      .then(
        () => {
          toast.success('Subscribed', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
            });
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  // --------------------------------------------contact---------------------------
  const[user, setUser] = useState(
    {
        Name: '', Number:'', Message:''
    }
  )
  // const [errorMsg, setErrorMsg] = useState("");
  let name, value
  const data = (e) =>
  {
    console.log(user)
    name = e.target.name;
    value = e.target.value;
    setUser({...user, [name]:value});
  }
  const getdata = async (e) => 
  {
  if (!user.Name || !user.Number || !user.Message) {
    // setErrorMsg("Fill all fields");
    return;
  }
    // setErrorMsg("");
    
    const{Name, Number, Message} = user;
    e.preventDefault();
    const options = {
        method: 'POST',
        headers: {
            'Content-type' : 'application/json'
        },
        body: JSON.stringify({
            Name, Number, Message
        })
    }
    const res = await fetch(
        'https://govyapar-contact-default-rtdb.firebaseio.com/UserData.json',
        options
        )
        if(res)
        {
          toast.success('Message sent successfully', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
            });
        }
        else
        {
            alert("Error Ocurred")
        }
      }

// ----------------------------------Ratings------------------------------------------
      const stats = [
        {
          icon: <FaStar className="text-3xl text-black" />,
          label: 'Google Rating',
          value: '4.9',
        },
        {
          icon: <FaDownload className="text-3xl text-black" />,
          label: 'App Download',
          value: '10 Lakh+',
        },
        {
          icon: <FaUsers className="text-3xl text-black" />,
          label: 'Users',
          value: '10 Lakh+',
        },
        {
          icon: <FaCommentDots className="text-3xl text-black" />,
          label: 'Reviews',
          value: '12,500+',
        },
        {
          icon: <FaHandshake className="text-3xl text-black" />,
          label: 'Partners',
          value: '3000+',
        },
      ];
    

      const benefits = [
        {
          number: '1',
          text: 'Get comprehensive advice on income tax, GST, capital gains, and more, all in a few clicks',
        },
        {
          number: '2',
          text: 'No delays translate into no missed deadlines, no penalties, and expedited refunds',
        },
        {
          number: '3',
          text: 'Access to qualified experts offering personalised services wherever you are',
        },
        {
          number: '4',
          text: 'Compete confidentiality and trust with hassle-free support for clients',
        },
      ];
      window.scrollTo(0,0);
  return (
    <>
    <div className=''>
    <a href='https://wa.me/919808030923'>
    <img src='WhatsappIcon.png' className='fixed w-[28%] md:w-[13%] lg:w-[10%] right-0 top-[520px] md:top-[470px] z-10' alt=''/></a>
     <Link to ="/scheduleCall"><button className='text-white bg-yellow-600 border-2 border-black p-2 rounded-full cursor-pointer z-20 fixed w-[40%] sm:w-[20%] md:w-[24%] lg:w-[20%] xl:w-[16%] right-3 top-[520px] md:top-[470px] overflow-hidden mt-16 sm:mt-24 xl:mt-24 text-xs md:text-lg'><i class="fa fa-clock text-white text-xs md:text-lg"></i> Schedule a Call</button></Link>
    </div>

    {/* -------------------------About us------------------------------- */}
    <motion.section 
    initial={{x:-100, opacity:0}}
    whileInView={{x:0, opacity:1}}
    transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
    className='container max-w-full mx-0 mt-20 xl:pl-60 pt-20 xl:px-32 lg:px-24 md:px-16 scroll-smooth'>
    <img src="./here about.png" className='md:float-right md:w-[40%] lg:w-[30%]' alt=''/>
    <h1 className='text-2xl md:text-4xl lg:text-5xl font-bold text-black leading-tight'>Collaborate with the Best Online Tax Filing Service in India at Your Fingertips</h1>
    <h1 className='text-lg md:text-xl lg:text-2xl font-bold uppercase leading-tight py-4 text-yellow-500'>A Tax Guidance Partner You Can Rely On to Verify Your Income Tax Return With Seasoned Experts</h1>
    <p className='text-lg text-justify'>
    Are you a business owner or salaried professional looking for tax guidance? Govyapar is here to offer the best tax filing service in India. With experts guiding you for better tax planning, assisted ITR filing, Resolving Income tax notices, and filing Tax appeals, you cannot go wrong with Govyapar.
    </p>
    </motion.section>

    <div className="bg-gray-100 py-8 mt-5 px-4">
      <h2 className="text-center text-2xl font-bold mb-6">Helping Clients With End-to-End Tax Preparation Service</h2>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-5  gap-6 text-center">
        {stats.map((stat, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="mb-2">{stat.icon}</div>
            <p className="text-lg font-semibold">{stat.label}</p>
            <p className="text-xl font-bold text-yellow-500">{stat.value}</p>
          </div>
        ))}y
      </div>
    </div>

    {/* -------------------------Comprehensive Services for Everyone with Experienced Tax Professionals------------------ */}
    <div className="text-center  mx-auto max-w-full my-20 md:px-20 lg:px-40">
      <h1 className="text-2xl md:text-4xl font-bold mb-4 px-4">
        Comprehensive Services for Everyone with Experienced Tax Professionals
      </h1>
      <p className="md:text-lg text-gray-700 text-justify px-4">
        As an Indian taxpayer, you know the complexities of tax filing and the consequences of the smallest error in your returns. We make sure that you never miss a deadline, make a calculation error, or omit an item from your returns. Our platform covers diverse needs and facilitates tax filing for everyone.
      </p>
        <motion.div 
        initial={{y:100, opacity:0}}
        whileInView={{y:0, opacity:1}}
        transition={{delay:0.2, y:{type:"spring", stiffness:60}, opacity:{duration:0.}, ease:"easeIn", duration:1}} className="grid grid-cols-1 grid-rows-5 md:grid-cols-5 md:grid-rows-1 justify-center items-center md:gap-4 md:pt-5">
        <div className="flex flex-col items-center mb-2">
          <img src="nerd_2230170.png" alt="Salaried Professionals" className="w-[30%] md:w-[40%]" />
          <p className="font-semibold">Salaried professionals</p>
        </div>
        <div className="flex flex-col items-center mb-2">
          <img src="apartment_15356033.png" alt="Small Business Owners" className="w-[30%] md:w-[40%]" />
          <p className="font-semibold">Small business owners</p>
        </div>
        <div className="flex flex-col items-center mb-2">
          <img src="hotel_884259.png" alt="Large Enterprises" className="w-[30%] md:w-[40%]" />
          <p className="font-semibold">Large enterprises</p>
        </div>
        <div className="flex flex-col items-center mb-2">
          <img src="trader_16770007.png" alt="Share Traders" className="w-[30%] md:w-[40%]" />
          <p className="font-semibold">Share traders</p>
        </div>
        <div className="flex flex-col items-center">
          <img src="dollar_4412243.png" alt="Capital Gain Earnings" className="w-[30%] md:w-[40%]" />
          <p className="font-semibold">Capital gain earnings</p>
        </div>
        </motion.div>
    </div>

    {/* ---------------------------------Our Services- Online ITR Filing & Beyond---------------------------------- */}
    <div className='py-10 mx-auto max-w-full my-20 md:px-20 lg:px-36'>
      <h1 className='text-2xl md:text-4xl text-center mb-4 font-bold text-black'>Our Services- Online ITR Filing & Beyond</h1>
      <p className='text-md md:text-lg px-2 md:px-32 text-justify text-black'>Besides being a seasoned tax filing expert you can depend on, we offer a broad range of services to cover your needs as a taxpayer. We can help you with</p>
      <div className="px-20 sm:px-40 md:px-10 py-4">
      <motion.ul 
      initial={{y:100, opacity:0}}
      whileInView={{y:0, opacity:1}}
      transition={{delay:0.2, y:{type:"spring", stiffness:60}, opacity:{duration:0.}, ease:"easeIn", duration:1}}
      className="grid grid-rows-4 md:grid-cols-4 md:grid-rows-1 gap-6">

    <li class="bg-white p-3 border-1 shadow-md shadow-gray-800 rounded-xl cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 text-center">
      <h1 className='font-bold text-lg text-center text-yellow-500 py-2'>Appeals:</h1>
      <p className='text-sm text-justify text-black'>Are you planning to appeal against the IT department’s decision? We can help you raise a concern.</p>
    </li>
    <li class="bg-white p-3 border-1 shadow-md shadow-gray-800 rounded-xl cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 text-center">
      <h1 className='font-bold text-lg text-center text-yellow-500 py-2'>Income Tax Notice:</h1>
      <p className='text-sm text-justify text-black'>With our experts, you need not worry about a tax-related notice from the department any more.</p>
    </li>
    <li class="bg-white p-3 border-1 shadow-md shadow-gray-800 rounded-xl cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 text-center">
      <h1 className='font-bold text-lg text-center text-yellow-500 py-2'>Tax planning expertise:</h1>
      <p className='text-sm text-justify text-black'>Our tax planning advice sets you up for tax savings and a hassle-free filing experience.</p>
    </li>
    <li class="bg-white p-3 border-1 shadow-md shadow-gray-800 rounded-xl cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 text-center">
      <h1 className='font-bold text-lg text-center text-yellow-500 py-2'>US tax filing:</h1>
      <p className='text-sm text-justify text-black'>Trust our seasoned professionals to help you with US tax filing for your business.</p>
    </li>

    </motion.ul>
    </div>
    </div>

    {/* ---------------------------About Govyapar Academy------------------------ */}
    <div className='py-10 mx-auto max-w-full my-20 md:px-20 lg:px-36'>
    <h1 className='text-2xl md:text-4xl text-center mb-4 font-bold  text-yellow-500'>About Govyapar Academy</h1>
    <p className='text-md md:text-lg px-2 mb-4 md:px-32 text-justify text-black'>We aren't just your tax filing expert. We also offer high-value courses to help you master tax filing like a pro. joining the Govyapar Academy sets you up for a broad range of benefits such as:</p>
    <motion.ul 
    initial={{y:100, opacity:0}}
    whileInView={{y:0, opacity:1}}
    transition={{delay:0.2, y:{type:"spring", stiffness:60}, opacity:{duration:0.}, ease:"easeIn", duration:1}}
    className="grid grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-6 px-16 lg:px-24">

    <li class="bg-white p-3 border-1 shadow-md shadow-gray-800 rounded-tl-xl rounded-br-xl cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 text-center">
      <p className='text-lg text-justify text-black'>Access to cost-effective learning</p>
    </li>
    <li class="bg-white p-3 border-1 shadow-md shadow-gray-800 rounded-tl-xl rounded-br-xl cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 text-center">
      <p className='text-lg text-justify text-black'>Collaboration with an interactive community</p>
    </li>
    <li class="bg-white p-3 border-1 shadow-md shadow-gray-800 rounded-tl-xl rounded-br-xl cursor-pointer md:hover:-translate-x-1 ease-in duration-300 hover:scale-105 text-center">
      <p className='text-lg text-justify text-black'>Flexible access to classes any time and from anywhere</p>
    </li>

    </motion.ul>
    </div>

    {/* ------------Why Seek Filing Advice From Knowledgeable Tax Advisors Online--------------------- */}
    <div className='py-10 mx-auto max-w-full my-20 md:px-20 lg:px-36'>
    <h1 className='text-2xl md:text-4xl mb-4 text-center font-bold text-black'>Why Seek Filing Advice From Knowledgeable Tax Advisors Online</h1>
    <p className='text-md md:text-lg px-2 md:px-32 text-justify text-black'>Tax filing advice can give you a winning advantage, whether you are a salaried employee or run a business as an owner. It can keep you from missing out on deadlines, prevent mistakes, and save you from audits and penalties. Seeking advice online is a great option because it saves you time, money, and stress:</p>
    <div className="bg-black rounded-xl mt-4 py-8 px-4">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4  gap-6">
        {benefits.map((benefit, index) => (
          <div key={index} className="bg-white p-6 rounded-xl shadow-md shadow-yellow-700 flex flex-col items-center text-center">
            <div className="bg-yellow-600 text-white rounded-full w-10 h-10 flex items-center justify-center text-lg font-bold relative bottom-10">
              {benefit.number}
            </div>
            <p className="text-gray-800">{benefit.text}</p>
          </div>
        ))}
      </div>
    </div>
    </div>

      {/* ---------------------------------------------------FAQS------------------------------------------------------- */}
      <section class=" text-black py-32 min-h-screen">
  <div class="container flex flex-col justify-center p-4 mx-auto md:p-8">
    <h2 class="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">Frequently Asked Questions</h2>
    <div class="flex flex-col divide-y sm:px-8 lg:px-20 xl:px-32 divide-yellow-600">
      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What is Govyapar?</summary>
        <div class="px-4 pb-4 text-justify">
          <p>Govyapar is a leading tax filing service provider, offering exceptional expertise, personalized solutions, and quality services to ensure maximum refunds and value for money in the ITR filing process.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Is Govyapar a private or a public company?</summary>
        <div class="px-4 pb-4 text-justify">
          <p>Govyapar is an online platform, that assists individuals and businesses with their tax needs. It is a simple and user-friendly platform. Govyapar was developed by SSBA Innovations Ltd., a public company known for its innovative financial solutions.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Who should use Govyapar's services?</summary>
        <div class="px-4 pb-4 text-justify">
          <p>Govyapar’s services are designed for a broad range of clients including salaried professionals, small business owners, large enterprises, and individuals with various types of income.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What makes Govyapar different from other tax filing services?</summary>
        <div class="px-4 pb-4">
          <p>Govyapar stands out for its team of seasoned chartered accountants, personalized tax solutions, certified tax preparers, seamless integration, and top-notch security measures to protect client data.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How does Govyapar ensure the quality and accuracy of its services?</summary>
        <div class="px-4 pb-4">
          <p>We employ certified tax preparers who adhere to the highest standards of accuracy and timeliness, ensuring every tax return is error-free.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can Govyapar help me if I receive an income tax notice?</summary>
        <div class="px-4 pb-4">
          <p>Yes, our experts are well-equipped to assist you in responding to any tax-related notices from the department, ensuring your peace of mind.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Does Govyapar offer tax planning services?</summary>
        <div class="px-4 pb-4">
          <p>Indeed, our tax planning expertise is designed to set you up for tax savings and a hassle-free filing experience.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Is Govyapar's platform easy to use for e-filing?</summary>
        <div class="px-4 pb-4">
          <p>Absolutely, our all-in-one digital platform simplifies the e-filing process, offering advice, guidance, and action in a few clicks.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Is Govyapar's platform easy to use for e-filing?</summary>
        <div class="px-4 pb-4">
          <p>Absolutely, our all-in-one digital platform simplifies the e-filing process, offering advice, guidance, and action in a few clicks.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What security measures does Govyapar take to protect my financial data?</summary>
        <div class="px-4 pb-4">
          <p>We prioritize your data's security with advanced privacy measures, ensuring that your financial information is always safe with us.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How can I get in touch with a Govyapar expert?</summary>
        <div class="px-4 pb-4">
          <p>You can connect with an expert either through our Schedule a Call facility or Live Chat feature, ensuring you have direct access to personalized tax advice.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What is the process of filing an ITR with Govyapar?</summary>
        <div class="px-4 pb-4">
          <p>The process is simple: Connect with an expert via Live Chat, share the necessary information, and file your ITR within 24 hours, quickly and accurately.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Can Govyapar assist with US tax filing?</summary>
        <div class="px-4 pb-4">
          <p>Yes, our team includes seasoned professionals who can assist with US tax filings for your business.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How does Govyapar ensure I get the maximum refund?</summary>
        <div class="px-4 pb-4">
          <p>Our experts use their extensive knowledge and expertise to ensure that each client receives the maximum possible refund.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What kind of appeals can Govyapar help with?</summary>
        <div class="px-4 pb-4">
          <p>We can assist you in appealing against decisions made by the Income Tax Department, ensuring your concerns are effectively raised.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Why should I seek online filing advice from Govyapar?</summary>
        <div class="px-4 pb-4">
          <p>Seeking advice from Govyapar online saves you time, money, and stress while ensuring you receive comprehensive, personalized services.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">Why should I seek online filing advice from Govyapar?</summary>
        <div class="px-4 pb-4">
          <p>Seeking advice from Govyapar online saves you time, money, and stress while ensuring you receive comprehensive, personalized services.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How has Govyapar grown since its inception?</summary>
        <div class="px-4 pb-4">
          <p>Since its conceptualization in 2017, Govyapar has grown to launch a broad range of services, secured funding rounds, and continuously expanded its platform to serve a wider audience.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What additional services does Govyapar offer beyond online ITR filing?</summary>
        <div class="px-4 pb-4">
          <p>Beyond ITR filing, we offer services for tax appeals, handling income tax notices, tax planning, and US tax filing, covering all your needs as a taxpayer.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How can Govyapar help small business owners and large enterprises?</summary>
        <div class="px-4 pb-4">
          <p>We provide tailored tax filing solutions that integrate seamlessly with your operations, ensuring a hassle-free experience for businesses of all sizes.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What is Govyapar's commitment to its clients?</summary>
        <div class="px-4 pb-4">
          <p>Our commitment is to deliver exceptional tax filing expertise, personalized solutions, and quality service, ensuring complete satisfaction of our clients.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How can Govyapar's platform benefit me as a salaried professional?</summary>
        <div class="px-4 pb-4">
          <p>Our platform offers salaried professionals a streamlined and efficient way to file their taxes, ensuring accuracy, maximum refunds, and peace of mind.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">What services does Govyapar offer for managing GST?</summary>
        <div class="px-4 pb-4">
          <p>Govyapar provides comprehensive online services for all your GST needs, including personalized advice on GST, assistance with obtaining GST registration, and guidance on return filing.</p>
        </div>
      </details>

      <details>
        <summary class="py-3 outline-none cursor-pointer text-lg md:text-2xl font-semibold focus:text-yellow-500">How can Govyapar help me with GST registration and return filing?</summary>
        <div class="px-4 pb-4">
          <p>With Govyapar, you can easily overcome the complexities of GST registration and return filing through our user-friendly online platform, backed by expert advice to ensure compliance and optimization.</p>
        </div>
      </details>
    </div>
  </div>
</section>
            {/* -----------------------------------------------Subscribe to our newsletter------------------------------------------------ */}
            <div class="container bg-black mt-20 pt-10 max-w-full scroll-smooth">
  <div class="md:flex justify-between items-center lg:px-28 py-10 ">
    <motion.div
     initial={{x:-100, opacity:0}}
     whileInView={{x:0, opacity:1}}
     transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
     >
      <h1 class="md:text-4xl text-2xl text-yellow-500 font-bold  sm:text-center md:text-left leading-tight">Subscribe to us!</h1>
      <p class="text-md text-white my-2">Subscribe to our newsletter for exclusive updates and offers.</p>
    </motion.div>

    <form ref={form} onSubmit={sendEmail}>
      <motion.div  initial={{x:-100, opacity:0}}
      whileInView={{x:0, opacity:1}}
      transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
      class="flex">
        <input 
        type="email" 
        placeholder="Enter your email" 
        name="user_email" 
        required 
        class="rounded-md px-4 py-2 w-full mr-2 focus:outline-none focus:ring-1 focus:ring-yellow-500" />
        <input 
        type="submit" 
        value="Subscribe" 
        class="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-4 rounded-md shadow-sm"/>

      </motion.div>
    </form>
  </div>


    {/* ----------------------------------------------------------Contact------------------------------------------------------- */}
<div>
  <h1 className="text-2xl text-yellow-500 font-bold text-center">Contact</h1>
  <motion.form method='POST' 
   initial={{y:-100, opacity:0}}
   whileInView={{y:0, opacity:1}}
   transition={{delay:0.10, y:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
    className="text-xl space-y-1 rounded-md mx-3 md:pt-10 pt-2 text-center sm:px-32 md:px-52 lg:px-[300px] xl:px-[500px]"> 
    <label className='text-white'>Name</label> <br/>
    <div className='flex'>
    <i class="fa fa-user icon text-black p-2 rounded-l-md bg-yellow-500"></i>
    <input 
    type='text' 
    name='Name' 
    placeholder='Enter your Name' 
    value={user.Name} 
    className='w-full p-1 rounded-r-md' 
    required
    onChange={data} 
    />
    </div>
    <br/>

    <label className='text-white'>Phone Number</label><br/>
    <div className='flex'>
    <i class="fa fa-phone icontext-black p-2 rounded-l-md bg-yellow-500"></i>
    <input 
    type='tel' 
    name='Number'
    value={user.Number} 
    placeholder="Enter Phone Number" 
  maxLength={10}
    className='w-full p-1 rounded-r-md' 
    required
    onChange={data} 
    />
    </div>
    <br/>

    <label className='text-white'>Message</label><br/>
    <div className='flex'>
    <i class="fa fa-edit icon text-black p-2 rounded-l-md bg-yellow-500"></i>
    <textarea 
    type='text' 
    name='Message'
    value={user.Message} 
    className='w-full p-1 rounded-r-md' 
    placeholder='Enter your Message' 
    required
    onChange={data} 
    >
    </textarea>
    </div>
    <button 
    onClick={getdata}  
    type="submit" 
    class="bg-yellow-500 hover:bg-yellow-600 text-black font-bold py-2 px-3 mt-4 rounded-md shadow-sm">
    SUBMIT
    </button>
      <ToastContainer
    position="top-center"
    autoClose={4000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
    transition= {Flip}
    />
  </motion.form>
</div>
<div className='mt-10 text-center lg:px-[200px]'>
<h1 class="text-xl text-yellow-500">GET IN TOUCH</h1>
<motion.ul
 initial={{x:-100, opacity:0}}
 whileInView={{x:0, opacity:1}}
 transition={{delay:0.10, x:{type:"spring", stiffness:60}, opacity:{duration:0.8}, ease:"easeIn", duration:1}}
  class="list-none justify-center md:flex md:space-x-5">
     
        <li className='font-semibold text-white text-lg'><i class="fa fa-phone text-yellow-500"> </i> +91-9808030923</li> 
        <a href= "mailto: support@govyapar.com"><li className='font-semibold text-white text-lg'><i class="fa fa-envelope text-yellow-500"> </i> support@govyapar.com</li> </a>     

<ul className='flex space-x-2 justify-center text-lg'>
        <a href='https://www.linkedin.com/company/govyapar/'><li><i class="fa fa-linkedin text-yellow-500 hover:font-bold"></i></li></a>
        {/* <li><i class="fa fa-twitter text-yellow-500 hover:font-bold"></i></li> */}
        <a href='https://www.instagram.com/govyapar/'><li><i class="fa fa-instagram text-yellow-500 hover:font-bold"></i></li></a>
        <a href = "https://www.facebook.com/govyapar"><li><i class="fa fa-facebook-f text-yellow-500 hover:font-bold"></i></li></a>
        <a href='https://www.youtube.com/@govyapar'><li><i class="fa fa-youtube text-yellow-500 hover:font-bold"></i></li></a>
</ul>
</motion.ul>
<div className='flex xl:px-36 px- pt-3 space-x-10 text-white cursor-pointer justify-center '>
    <div className='mx-auto '>
             <ul className='grid grid-cols-2 grid-rows-2 sm:grid-cols-4 sm:grid-rows-1 font-thin text-white mx-auto px-3 text-justify'>
            <div className='text-sm md:text-lg'>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>MENU</li>
            <Link to="/home"><li className=' hover:text-yellow-500'>Home</li></Link>
             <Link to="/about"> <li className=' hover:text-yellow-500'>About Us</li></Link>
            <Link to="/career"><li className=' hover:text-yellow-500'>Careers</li></Link>
            <Link to="/pricing"><li className=' hover:text-yellow-500'>Pricing</li></Link>
            <Link to="/articles"><li className=' hover:text-yellow-500'>Articles</li></Link>
            </div>

            <div className='text-sm md:text-lg'>
            <Link to="/affiliate"><li className=' hover:text-yellow-500'>Affiliate Program</li></Link>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>SERVICES</li>
            <Link to="/itrFiling"><li className=' hover:text-yellow-500'>ITR Filing</li></Link>
            <Link to="/gstFiling"><li className=' hover:text-yellow-500'>GST Filing</li></Link>
            <Link to="/notice"><li className=' hover:text-yellow-500'>Notices</li></Link>
            <Link to="/consultation"> <li className=' hover:text-yellow-500'>Consultation</li></Link>
            </div>

            <div className='text-sm md:text-lg'>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>SERVICES</li>
            <Link to="/tax-planner"> <li className=' hover:text-yellow-500'>Tax Planner</li></Link>
            <Link to="/ngo"><li className=' hover:text-yellow-500'>NGO</li></Link>
            <Link to="/trademark"><li className=' hover:text-yellow-500'>Trademark</li></Link>
            <Link to="/startupFunding"><li className=' hover:text-yellow-500'>Startup Funding</li></Link>
            </div>

            <div className='text-sm md:text-lg'>
            <li className='text-sm md:text-lg font-bold text-yellow-500'>SERVICES</li>
            <Link to="/virtualCfo"> <li className=' hover:text-yellow-500'>Virtual CFO</li></Link>
            <Link to="/businessLoans"> <li className=' hover:text-yellow-500'>Business Loans</li></Link>
            <Link to="/"><li className=' hover:text-yellow-500'>ISO Certificate</li></Link>
            <Link to="/companyFormation"><li className=' hover:text-yellow-500'>Company Formation</li></Link>
            {/* <Link to="/tax-planner"><li className=' hover:text-yellow-500'> GET ECA FOR ITR FILING</li></Link> */}
            </div>
          </ul>
    </div>
    </div>
</div>
{/* <p className='border-dotted w-full  border-yellow-500 border-2'></p> */}

{/* ------------------------------------------------Footer Links------------------------------------------------- */}
<hr className='text-white w-full my-3'/>

{/* ---------------------------------------------Foooter------------------------------------------- */}
<footer className='text-white text-sm text-center mb-0 mt-2'>
  ©Copyright 2024. All Rights Reserved. Govyapar
</footer>

</div> 
    </>
  )
}

export default AboutUs
