import React from 'react';
import {  Link } from 'react-router-dom';

// Services data
const services = [
  { name: 'ITR Filing', description: 'Tax filing by tax experts', link:'/itrFiling', icon: 'articles itr.png' },
  { name: 'Notices', description: 'Resolve notices of all types', link:'/notice', icon: 'articles notice.png' },
  { name: 'Consultation', description: 'Plan and reduce your tax liability', link:'/consultation', icon: 'articles consultation.png' },
  { name: 'NGO', description: 'Register Your NGO', link:'/ngo', icon: 'articles ngo.png' },
  { name: 'Startup Funding', description: 'Startup Funding', link:'/startupFunding', icon: 'articles startup funding.png' },
  { name: 'Business Loans', description: 'Business Loans', link:'/businessLoans', icon: 'articles business loans.png' },
  { name: 'GST Filing', description: 'GST registration and timely filing', link:'/gstFiling', icon: 'tax_9323080.png' },
  { name: 'Company Formation', description: 'Expert guidance for incorporation', link:'/companyFormation', icon: 'articles company formation.png' },
  { name: 'Tax Planner', description: 'Plan and reduce your tax liability', link:'/tax-planner', icon: 'articles tax planner.png' },
  { name: 'Trademark', description: 'Apply Trademark Registration Online', link:'/trademark', icon: 'articles trademark.png' },
  { name: 'Virtual CFO', description: 'Virtual CFO', link:'/virtualCfo', icon: 'articles Virtual CFO.png' },
  { name: 'ISO Certification', description: 'ISO Certification', link:'/itrFiling', icon: 'articles ISO Certification.png' },
];

// Sidebar component
const Sidebar = () => {
  window.scrollTo(0,0);
  return (
    <div className="w-64 h-auto hidden md:block bg-yellow-500 text-black mt-24 sticky">
      <div className="px-8 pt-4 text-xl font-bold">Services</div>
      <ul className="space-y-2 p-4">
        {services.map((service, index) => (
          <li key={index} className="p-2 rounded hover:bg-gray-700 cursor-pointer">
          <div className='flex space-x-2'>
          <img src={service.icon} className='w-[10%] md:w-[25%] lg:w-[30%]'/>
          <Link to={service.link} className="font-semibold text-xl hover:text-white pt-2">{service.name}</Link>
          <div className='flex flex-col'>
          {/* <Link to={service.link} className="font-semibold">{service.name}</Link>  */}
          {/* <span className="text-sm text-gray-400">{service.description}</span> */}
          </div>
          </div>
        </li>
        ))}
      </ul>
    </div>
  );
};

// Articles data
const articles = [
  {
    title: 'Start, maintain Public Provident Fund account, even in new tax regime',
    category: 'DGFT',
    description: 'The government expects investments in small savings instruments, especially Public Provident Fund (PPF), to plummet sharply in 2024-25 with over...',
    date: 'August 8, 2024',
    imageUrl: 'hand-drawn-essay-illustration (1).png',
    link: '/page1'
  },
  {
    title: 'Sovereign gold bond redemption value falls over 4% on Budget duty cut',
    category: 'DGFT',
    description: 'The redemption price of sovereign gold bond (SGB) scheme (SGB 2016 -17 Series I - Issue date August 05, 2016)...',
    date: 'August 8, 2024',
    imageUrl: 'hand-drawn-essay-illustration (1).png',
    link: '/page2'
  },
  {
    title: 'Enhancing Internal Audit Processes Through Application of 5WIH Method',
    category: 'CA, CS, CMA',
    description: 'Learn how to apply the 5WIH method in internal audits to improve accuracy, identify issues, and streamline processes. Enhance your...',
    date: 'August 5, 2024',
    imageUrl: 'hand-drawn-essay-illustration.png',
    link: '/page3'
  },
  {
    title: '‘Rapido’ is an E-commerce Operator & liable to GST: Karnataka AAR',
    category: 'Goods and Services Tax',
    description: 'Stay updated with key notifications and circulars on Income Tax, GST, Central Excise, Customs, DGFT, and SEBI issued between 29th...',
    date: 'August 5, 2024',
    imageUrl: 'hand-drawn-essay-illustration (1).png',
    link: '/page4'
  },
  {
    title: 'Registration Procedure for Obtaining IEC Code',
    category: 'DGFT',
    description: 'Learn the step-by-step procedure for obtaining an IEC code for import/export in India, including necessary documents and registration...',
    date: 'August 5, 2024',
    imageUrl: 'hand-drawn-essay-illustration (1).png',
    link: '/page'
  },
  // Add more articles as needed
];

// ArticleCard component
const ArticleCard = ({ title, category, description, date, imageUrl, link }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-3">
      <h3 className="text-md md:text-xl font-bold text-gray-800 mt-3 px-4">{title}</h3>
      <div className="text-yellow-600 text-sm px-4">
        <span>{category}</span> <br/><span className="font-medium">{date}</span>
      </div>
      <div className="px-4 md:grid md:grid-cols-2 md:grid-rows-1">
        <img src={imageUrl} alt={title} className="w-[60%] mx-auto md:mx-0" />
        <div className="mt-2">
          <p className="text-gray-700 text-sm md:text-md">{description}</p>
          <Link to={link}><button className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 my-2 px-4 font-medium rounded">Read more </button></Link>
        </div>
      </div>
    </div>
  );
};

// Articles component
const Articles = () => {
  return (
    <>
      <div className="max-w-5xl mt-32 mx-auto over">
        <h2 className="text-xl font-bold mb-2">
          <i className="material-icons text-3xl">article</i>
          <span className="ml-2 text-3xl">Latest Articles</span>
        </h2>
      </div>
      <div className="max-w-5xl mx-auto p-2 mt-4">
        {articles.map((article, index) => (
          <ArticleCard
            key={index}
            title={article.title}
            category={article.category}
            description={article.description}
            date={article.date}
            imageUrl={article.imageUrl}
            link={article.link}
          />
        ))}
      </div>
    </>
  );
};

// LatestArticles component
const LatestArticles = () => {
  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow md:ml-3">
        <Articles />
      </div>
    </div>
  );
};

export default LatestArticles;
